<template>
  <!-- <h1>The Referral Handle</h1>-->
  <section class="screen_block bg_pennie_gray margin_tb_20">
    <div class="flex_fully_centered_space_between_row margin_lr_20 margin_t_10 flex_align_items_center max_w_vw">
      <div class="flex_left_aligned_column margin_tb_20">
        <h3>{{ prospect_data.prospect_client }}</h3>
        <p><strong>{{ phoneFormat(prospect_data.prospect_phone_number) }}</strong></p>
      </div>

      <div class="flex_centered_column">
        <h4>{{prospect_data.market}}</h4>
        <div class="flex_end_row">
          <basic_toggle ref="ad_handle_toggle"
                        class="margin_lr_10"
                        uuid=""
                        :state_disabled="false"
                        @toggle-state_true="toggle_is_true"
                        @toggle-state_false="toggle_is_false"
          ></basic_toggle>
          <basic_text_button
              class="margin_t_10"
              :button_state="buttons_active"
              :hover_color="colors.new_orange_main"
              :button_color="colors.new_orange_main"
              :text_color="colors.black"
              :button_color_not_ready="colors.gray"
              :button_not_ready_text_color="colors.morgie_gray"
              button_height="40px"
              button_width="100px"
              border_rad_pct="5%"
              text="Retire Prospect"
              button_not_ready_text="Retire Prospect"
              text_size="14px"
              :bold="true"
              event_name="retire_prospect_click"
              @retire_prospect_click="retire_prospect(prospect_data.name)"
          ></basic_text_button>
        </div>
      </div>


    </div>
    <div class="flex_centered_row margin_b_30 margin_t_20"><p class="font_weight_800">Referrals Made</p></div>
      <div class="flex_start_row flex_wrap">
      <div v-for="(referral, index) of prospect_data.referrals" :key="`item-${index}`">
        <Referral_Given
            :agency=referral.agency
            :referral_timestamp=referral.referral_timestamp
        ></Referral_Given>

      </div>

    </div>

    <div class="margin_t_30">
      <select_and_launch_2
          class="margin_auto bg_eli_gray font_raleway font_weight_800"
          label='Refer Prospect to an Agency'
          :label_color=colors.black
          selection_title="PDAs"
          button_text="Refer"
          :values=prospect_data.non_referred_agencies
          event_name="refer_prospect"
          @refer_prospect="refer_prospect_click"
      ></select_and_launch_2>
    </div>


  </section>
</template>

<script>
/* eslint-disable */
import basic_toggle from "@/components/parts/basic_toggle";
import basic_text_button from "@/components/parts/basic_text_button";
import Referral_Given from "@/components/Vortex/admin_interface/Referral_Given.vue";
import {colors, translate_utc_std_timestring_to_local_pretty_timestring, isEmpty, formatPhoneNumber} from "@/library";

import store from "@/store";
import select_and_launch_2 from "@/components/parts/select_and_launch_2.vue";

export default {
  name: "referral_handle",
  components: {
    select_and_launch_2,
    basic_toggle,
    basic_text_button,
    Referral_Given
  },
  props: ["prospect_data"],
  data() {
    return {
      colors: colors,
      toggle_disabled_: false,
      buttons_active_: false,
      values: ['rah', 'abc']
    }
  },
  methods: {
    do_nothing(){
      console.log("Doing nothing ao6486")
    },
    isEmpty,
    refer_prospect_click(agency_getting_referral) {
      console.log("refer_prospect_click at refferral handle leveel")
      console.log(agency_getting_referral)
      this.$emit('refer_prospect_click', [agency_getting_referral, this.prospect_data.name])
    },
    phoneFormat(phone) {
      return formatPhoneNumber(phone)
    },
    not_implemented_notification() {
      if (this.buttons_active) {
        let message = "This feature is not yet implemented."
        store.commit('notification_enqueue', message)
        this.toggle_is_false()
      }

    },
    toggle_is_true() {
      this.buttons_active_ = true
    },
    toggle_is_false() {
      this.buttons_active_ = false
      this.$refs.ad_handle_toggle.toggle_state_to_false()
    },
    // extent_ad(){
    //   this.emit_id("extent_ad")
    // },
    retire_prospect(prospect_uid) {
      console.log(`Retiring  the prospect: ${prospect_uid}`)
      if (this.buttons_active) {
        this.$emit('retire_prospect_click', prospect_uid)
        this.toggle_is_false()
      }
    },
    extend_ad() {
      console.log(`Extending the ad id: ${this.ad_id}`)
      if (this.buttons_active) {
        this.emit_id("extend_ad")
        this.toggle_is_false()
      }
    },
    emit_id(name) {
      this.$emit(name, this.ad_id)
    },
    async handle_view_click(name) {
      // store.commit(`Click handling: "${uuid}"`, 'notification_enqueue')
      console.log(`View clicked ${name}`)
      // let tmh_name = this.ad_handle_values.translations[name]
      // store.commit('notification_enqueue', `${tmh_name} Clicked`)
    },

    async handle_click_click(name) {


      // store.commit(`Click handling: "${this.ad_handle_values['uuid']}"`, 'notification_enqueue')
      // store.commit('notification_enqueue', `Click handling: "${this.ad_handle_values['uuid']}"`)

      console.log(`Handlinin click id  ${this.ad_handle_values}`)
      // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)
      // store.commit('notification_enqueue', `${name} Clicked`)
      // console.log(this.ad_handle_values.translations)
      // let tmh_name = this.ad_handle_values.translations[name]


      let tmh_name = name
      // store.commit('notification_enqueue', `${tmh_name} Clicked`)


      store.commit('update_context_changing_route', false)
      // console.log("Just set this is a non-context changing route.")
      // console.log(`The current preamble is ${JSON.stringify(store.getters.find_current_preamble)}`)


      let latest = localStorage.getItem('dev_latest_route')
      // console.log(`Here is the current latest: ${latest}`)


      let this_route = `${latest}/standard/interested/${tmh_name}/${this.ad_handle_values['ad_id']}`
      // console.log("Here is the attempted route.")
      // console.log(this_route)
      // store.commit('save_latest_route', this_route)
      // console.log(`Going to ${this_route}`)


      await this.$router.push({path: this_route})


    },
  },
  computed: {
    toggle_disabled() {
      return this.toggle_disabled_
    },
    buttons_active() {
      return this.buttons_active_
    },
    expires_pretty() {
      return translate_utc_std_timestring_to_local_pretty_timestring(this.expires)
    },
  },
}
</script>

<style scoped>

@media (min-width: 100px) {
  .handle_headline_text {
    margin: 10px
  }

  .handle_body_text {
    margin: 15px
  }

  .ad_handle_details {
    font-size: 15px;
    max-width: 100vw;
  }

}


</style>