<template>
  <div class="screen_block margin_tb_20  flex_centered_row margin_auto bg_white ">
    <div class="flex_centered_column ">
      <img class="tmh_color_gradient padding_tb_30 logo"
           src="@/assets/vortex.svg"
           alt="Vortex Logo">
    </div>

  </div>

  <div class="margin_tb_20 flex_centered_column">

    <select_and_launch_2
        class="margin_auto bg_whitesmoke font_raleway font_weight_800"
        label='Subscribe a PDA to Vortex'
        :label_color=colors.black
        selection_title="PDAs"
        button_text="Subscribe"
        :values="non_subscribing_pdas"
        event_name="create_pda_spyglass_subscription"
        @create_pda_spyglass_subscription="create_pda_vortex_subscription"
    ></select_and_launch_2>
  </div>

  <div class="margin_t_20 margin_b_10 flex_centered_column">
    <h1>Prospect Manager</h1>
    <referral_widget
        :prospects=prospect_data
        @page_change="local_page_update"
    >
    </referral_widget>

  </div>

</template>
<script>
/* eslint-disable */
import select_and_launch_2 from "@/components/parts/select_and_launch_2";
import dual_select_and_launch from "@/components/parts/dual_select_and_launch";
import referral_widget from "@/components/Vortex/admin_interface/referral_widget.vue";
import {be_main_address, colors, parse_internal_json} from "@/library";
import store from "@/store";
import axios from "axios";

export default {
  name: "tmh_vortex_controls",
  components: {select_and_launch_2, dual_select_and_launch, referral_widget},
  async mounted() {
    this.non_subscribing_pdas_ = await this.get_list_of_non_subscribers()
    await this.get_prospect_data()
  },
  data() {
    return {
      colors: colors,
      non_subscribing_pdas_: "",
      selected_subscriber_: "",
      prospects_: [],
      // prospects: [
      //   {
      //     'prospect_client': 'Supatrisimo Simpson',
      //     'prospect_phone_number': '9686656589',
      //     'market': 'Myrtle Beach',
      //     'referrals': [
      //       {
      //         'agency': 'RAH1185',
      //         'referral_timestamp': "2025-01-15T15:19:00Z"
      //       },
      //       {
      //         'agency': 'ABC4567',
      //         'referral_timestamp': "2025-01-15T15:19:00Z"
      //       },
      //       {
      //         'agency': 'BBB4567',
      //         'referral_timestamp': "2025-01-15T15:19:00Z"
      //       },
      //       {
      //         'agency': 'CCC4567',
      //         'referral_timestamp': "2025-01-15T15:19:00Z"
      //       },
      //     ],
      //     'non_referred_agencies': ['ZAC123', 'CEN359', 'BCO686'],
      //   },
      //   {
      //     'prospect_client': 'Calvin Client',
      //     'prospect_phone_number': '6866681368',
      //     'market': 'Wilmington',
      //     'referrals': [
      //       {
      //         'agency': 'RAH1185',
      //         'referral_timestamp': "2025-01-16T23:36:00Z"
      //       },
      //       {
      //         'agency': 'ABC4567',
      //         'referral_timestamp': "2025-01-16T23:36:00Z"
      //       },
      //     ],
      //     'non_referred_agencies': ['ZAC123', 'CEN359', 'BCO686'],
      //   }
      // ],


    }
  },
  computed: {
    non_subscribing_pdas() {
      return this.non_subscribing_pdas_
    },
    selected_subscriber() {
      return this.selected_subscriber_
    },
    prospect_data() {
      console.log("Inside prospect_data****************************************")
      // console.log(this.prospects)
      return this.prospects_
    },

  },
  methods: {

    do_nothing() {
      console.log("doing nothing.")
    },

    async local_page_update() {
      console.log("Running local page update and fetching the prospect data.")
      await this.get_list_of_non_subscribers()
      await this.get_prospect_data()
    },

    async create_pda_vortex_subscription(state) {
      console.log(`Caught the create subscription event for ${state}.`)
      this.selected_subscriber_ = state
      await this.subscribe(state)
      await this.local_page_update()
    },

    async get_prospect_data() {
      let target_url = be_main_address('vortex') + 'get_hch_prospect_data';

      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        prospect: null // Adjust this field if specific prospect data is needed
      };

      try {
        const response = await axios.post(target_url, data1);

        if (response.status === 200 || response.statusText === "OK") {
          if (response.data['error'] === true) {
            let message = parse_internal_json(response.data)['payload']['content']['message'];
            store.commit('error_enqueue', message);
          } else {
            console.log("Here is what I got as far as Prospect data goes...")
            console.log(response.data.data)
            this.prospects_ = response.data.data; // Save the response data to a Vue instance property
          }
        } else {
          console.error("SERVER ERROR");
          console.error(response);
        }
      } catch (error) {
        console.error("An error occurred while fetching HCH Prospect data:", error);
      }
    },

    async get_list_of_non_subscribers() {
      let target_url = be_main_address('vortex') + 'get_non_subscribers'
      // let target_url = 'http://192.168.1.10:8087/ft/get_non_subscribers'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        subscriber: null
      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let message = parse_internal_json(response.data)['payload']['content']['message']
                store.commit('error_enqueue', message)
              } else {
                this.non_subscribing_pdas_ = response.data
              }
            } else {
              console.log("SERVER ERROR")
              console.log(response)
            }
          })
    },

    async subscribe(subscribee) {
      let target_url = be_main_address('vortex') + 'create_subscription'
      // let target_url = 'http://192.168.1.10:8087/ft/create_subscription'
      let data1 = {
        caller: store.getters.my_name,
        id_token: store.getters.my_id_token,
        refresh_token: store.getters.my_refresh_token,
        // subscriber: "",
        subscribing_agency: subscribee

      }

      axios.post(target_url, data1)
          .then((response) => {
            if (response.statusText === "OK" | response.status === 200) {
              console.log("in axios...response.data is")
              console.log(response.data)
              console.log(typeof response.data)
              let message = `${subscribee} has been subscribed to Vortex.`
              store.commit('notification_enqueue', message)
            } else {
              console.log("SERVER ERROR")
              console.log(response)
              return "this is an error"
            }
          })
    },

  }
}


</script>

<style scoped>

.logo {
  width: 200px;
}
</style>