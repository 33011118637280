import {createStore} from 'vuex'
// import {shallowRef} from 'vue'
// import tmh_admin_main from "@/components/orgs/tmh_admin/tmh_admin_main";
// const tmh_admin_main_ref = shallowRef(tmh_admin_main)
// const profile_personal_ref = shallowRef(profile_personal)
// import profile_personal from "@/components/profiles/profile_personal";
import {
    Queue, compare_timestamp_reverse,
    compare_timestamp, getUniqueListBy, sort_stickies_and_regs, get_uuid, timestamp, play_sound
} from "@/library";


export default createStore({

    state() {
        return {
            main_title: "Tiny Magic Hat",
            og_main_meta_title: "Tiny Magic Hat",
            og_main_meta_url: "tinymagichat.com",
            main_meta_description: "Tiny Magic Hat is a platform dedicated to making caregiving a better job for professional caregivers.",
            og_main_meta_description: "Tiny Magic Hat is a platform dedicated to making caregiving a better job for professional caregivers.",
            og_main_image: "https://tinymagichat.com/img/coffee_couch.31f6f1ab.jpg",
            socket_connection: '',
            good_socket_connection: false,
            token_time: "00:00",
            network_kbps: 0,
            reestablish_socket_connection_counter: 0,
            windowWidth: window.innerWidth,
            windowWidthOuter: window.outerWidth,
            // windowWidth: window.innerWidth,

            safari_context: "",
            windowHeight: window.outerHeight,
            windowHeightinner: window.innerHeight,
            activate_log_out: false,
            username: '',
            password: '',
            email: '',
            phone_number: '',
            ip_address: '',
            attempt_reentry_route: '',
            // route_components: {
            //     person: profile_personal_ref,
            //     private_duty_agency: profile_personal_ref,
            //     tmh_admin: tmh_admin_main_ref,
            // },
            spinner_up: false,
            update_available: 'unknown',
            mobile_state: true,
            proposed_preamble: '[]',
            latest_route: "",
            context_changing_route: true,
            route_component: 'person',
            current_error_message: '',
            notification_queue: '',
            continue_queue: '',
            error_queue: '',
            analytics_queue: '',
            universal_page_update: 0,
            no_scroll_order: {},
            agency_logos: {},
            new_logo_available: false,
            assoc_invitations: {},
            camera_widget: {},
            pname_to_tmhname_map: null,
            outside: {
                inner_route: ""
            },
            vortex: {
                current_searcher: "",
                current_agency: ""
            },
            mother_pda: {
                states: {
                    candidate_already_exists: "",
                    user_exists: "",
                    all_pdas: "",
                },
                dropbox: {
                    indb_update: 0,
                    backend_update: 0,
                    prime_socket: 0
                },
            },
            personal_profile: {
                image_str: "",
                profile_text: {},
            },
            personal_badge_data: null,
            badge_data: {},
            agency_profile: {
                image_str: "",
                profile_text: {},
            },

            freetime: {
                reports: {
                    summary: {},
                    caregivers: {},
                    agency_message: "",
                },
                freetime_weeks_available: {

                },
                files: {
                    data: '',
                    match: '',
                    exclude: '',
                    exclude_template: '',
                    match_template: ''
                },
                file_names: {
                    data: '',
                    match: '',
                    exclude: '',
                },
                dropbox: {
                    indb_update: 0,
                    history_reports_update: 0,
                    prime_socket: 0
                },
                states: {
                    exclusions_at_server: false,
                    match_at_server: false
                },
                break_even: {
                    multiplier: 0,
                    funded_amount: 0,
                    highest_bonus: 0,
                    highest_pay_rate: 0,
                    highest_rate_hours: 0,
                    break_even_hours: "None",
                    avg_bill_rate: "",
                    avg_base_pay: "",
                    payroll_burden: "",
                    base_hours: "",
                },
            },
            chat: {
                user_session_id: "",
            },
            spyglass: {
                agency_landing: {
                    headline: "",
                    body: "",
                    logo: "",
                    preview_published: false,
                    ready_to_save: false,
                    phone_number: null
                },
                agency_landing_public_cache: {},
                current_ad_agency: "",

                schedule_ads: {
                    ads: [],
                },
                user_session_id: ""
            },
            speedydelivery: {
                agency_selected_caregiver: "",
                agency_selected_caregiver_pname: "",
                no_doc_caregivers: [],
                files: {
                    drivers_license: '',
                    car_insurance: '',
                    drug_test: '',
                    covid_record: '',
                },
                current_profile_image: "",
            },
            for_caregivers: {
                show_freetime_section: "",
                show_spyglass_section: "",
                show_speedydelivery_section: "",

            },

            for_agencies: {
                show_freetime_section: "",
                show_spyglass_section: "",
                show_speedydelivery_section: "",

            },
            main_cache: {},
            carousel_quotes: {
                home: [
                    {
                        text1: 'FreeTime is great. I\'ve opened a savings account with the extra money I\'ve made for doing a job I love.',
                        text2: 'Darcie P.',
                        id: 1
                    },
                    {
                        text1: 'I appreciate FreeTime, it\'s really helped me. I tell a lot of other people about this agency because of it.',
                        text2: 'Kathy M.',
                        id: 2
                    },
                    {text1: 'FreeTime has been very good for me.', text2: 'Nancy K.', id: 3},
                    {text1: 'Yes, FreeTime has been pretty helpful to me.', text2: 'Naomi L.', id: 4},
                    {
                        text1: 'FreeTime is a great incentive package. The higher pay rate is very motivating to me.',
                        text2: 'Emma T.',
                        id: 5
                    }
                ],
                for_caregivers: [
                    {
                        text1: 'FreeTime is great. I\'ve opened a savings account with the extra money I\'ve made for doing a job I love.',
                        text2: 'Darcie P.',
                        id: 1
                    },
                    {
                        text1: 'I appreciate FreeTime, it\'s really helped me. I tell a lot of other people about this agency because of it.',
                        text2: 'Kathy M.',
                        id: 2
                    },
                    {text1: 'FreeTime has been very good for me.', text2: 'Nancy K.', id: 3},
                    {text1: 'Yes, FreeTime has been pretty helpful to me.', text2: 'Naomi L.', id: 4},
                    {
                        text1: 'FreeTime is a great incentive package. The higher pay rate is very motivating to me.',
                        text2: 'Emma T.',
                        id: 5
                    }
                ],
                for_agencies: [
                    {
                        text1: 'FreeTime is great. I\'ve opened a savings account with the extra money I\'ve made for doing a job I love.',
                        text2: 'Darcie P.',
                        id: 1
                    },
                    {
                        text1: 'I appreciate FreeTime, it\'s really helped me. I tell a lot of other people about this agency because of it.',
                        text2: 'Kathy M.',
                        id: 2
                    },
                    {text1: 'FreeTime has been very good for me.', text2: 'Nancy K.', id: 3},
                    {text1: 'Yes, FreeTime has been pretty helpful to me.', text2: 'Naomi L.', id: 4},
                    {
                        text1: 'FreeTime is a great incentive package. The higher pay rate is very motivating to me.',
                        text2: 'Emma T.',
                        id: 5
                    }
                ],
            },
            anon_uuid: "",
            forums: {
                main_forum: {
                    threads: [],
                    posts: [],
                    page_count: 0,
                    block_size: 10,
                },
            },
            page_log: {
                durations: {},
            },

        };
    },
    mutations: {

        // get_current_speedydelivery_document(state, doc_type) {
        //     console.log(`Inside get current speedy document with doc type ${doc_type}`)
        //     console.log(state.speedydelivery.files[doc_type])
        //     return state.speedydelivery.files[doc_type]
        // },

        set_cache_value(state, cache_params) {
            //Cached values will keep for for 1 day by default
            let key = cache_params.key
            let value = cache_params.value
            let cache_time = 86400
            if(cache_params.cache_time){
                cache_time = cache_params.cache_time
            }
            // console.log(`Saving key ${key} and value ${value} for ${cache_time} seconds.`)
            let now_seconds = timestamp(true)
            let expires = now_seconds + cache_time
            let the_cache = state.main_cache
            the_cache[key] = [value, expires]
            state.main_cache = the_cache
            // console.log("Printing the main cache...")
            // console.log(state.main_cache)
        },

        good_socket_connection_status(state, value) {
            if(typeof value === 'boolean'){
                 state.good_socket_connection = value
            }else{
                state.good_socket_connection = false
            }
        },

        set_token_time(state, value) {
            if(typeof value === 'string'){
                 state.token_time = value
            }else{
                state.token_time = "00:00"
            }
        },
         set_network_speed(state, value) {
            state.network_kbps = value

        },
         set_current_vortex_agency(state, value) {
            state.vortex.current_agency = value
        },
        set_current_vortex_searcher_name(state, value) {
            state.vortex.searcher_name = value
        },
        set_current_vortex_client_name(state, value) {
            state.vortex.client_name = value
        },
        set_current_vortex_searcher_phone(state, value) {
            state.vortex.searcher_phone = value
        },
         set_current_vortex_client_phone(state, value) {
            state.vortex.client_phone = value
        },
        
        set_current_vortex_searcher_data(state, value){
            state.vortex.current_searcher = value
        },
        set_current_vortex_collected_data(state, value){
            state.vortex.collected_data = value
        },


        set_safari_store(state, value) {
            state.safari_context = value
        },

         set_pname_tmhname_map(state, value) {
            state.pname_to_tmhname_map = value
        },

        reset_speedydelivery_files_data(state) {
            for (const doc_type in state.speedydelivery.files) {
                state.speedydelivery.files[doc_type] = ""
            }
        },
        speedydelivery_add_caregiver_to_no_docs_list(state, cg) {
            console.log(`ADding ${cg} to the vuex no docs list`)
            state.speedydelivery.no_doc_caregivers.push(cg)
        },
        speedydelivery_remove_caregiver_to_no_docs_list(state, cg) {
            console.log(`removing ${cg} to the vuex no docs list`)
            let a = state.speedydelivery.no_doc_caregivers
            a = a.filter(function (a) {
                return a !== cg
            })
            state.speedydelivery.no_doc_caregivers = a
        },
        speedydelivery_reset_no_docs_list(state) {
            state.speedydelivery.no_doc_caregivers = []
        },


        get_carousel_quotes(state, collection) {
            console.log('Here are the quotes...')
            console.log(state.carousel_quotes)
            console.log(state.carousel_quotes[collection])
            return state.carousel_quotes[collection]
        },
        activate_section_for_caregivers(state, message) {
            console.log(`activate_section_for_caregivers with ${message}`)
            if (message === 'freetime') {
                state.for_caregivers.show_freetime_section = true
                state.for_caregivers.show_spyglass_section = false
                state.for_caregivers.show_speedydelivery_section = false
            } else if (message === 'spyglass') {
                state.for_caregivers.show_freetime_section = false
                state.for_caregivers.show_spyglass_section = true
                state.for_caregivers.show_speedydelivery_section = false
            } else if (message === 'speedydelivery') {
                state.for_caregivers.show_freetime_section = false
                state.for_caregivers.show_spyglass_section = false
                state.for_caregivers.show_speedydelivery_section = true
            } else {
                state.for_caregivers.show_freetime_section = false
                state.for_caregivers.show_spyglass_section = false
                state.for_caregivers.show_speedydelivery_section = false
            }
            console.log("after activation, state is...")
            console.log(`freetime: ${state.for_caregivers.show_freetime_section}`)
            console.log(`spyglass: ${state.for_caregivers.show_spyglass_section} `)
            console.log(`speedydelivery: ${state.for_caregivers.show_speedydelivery_section}`)
        },
        activate_section_for_agencies(state, message) {
            console.log(`activate_section_for_agencies with ${message}`)
            if (message === 'freetime') {
                state.for_agencies.show_freetime_section = true
                state.for_agencies.show_spyglass_section = false
                state.for_agencies.show_speedydelivery_section = false
            } else if (message === 'spyglass') {
                state.for_agencies.show_freetime_section = false
                state.for_agencies.show_spyglass_section = true
                state.for_agencies.show_speedydelivery_section = false
            } else {
                state.for_agencies.show_freetime_section = false
                state.for_agencies.show_spyglass_section = false
                state.for_agencies.show_speedydelivery_section = true
            }
            console.log("after activation, state is...")
            console.log(`freetime: ${state.for_agencies.show_freetime_section}`)
            console.log(`spyglass: ${state.for_agencies.show_spyglass_section} `)
            console.log(`speedydelivery: ${state.for_agencies.show_speedydelivery_section}`)
        },
        set_update_available(state, message) {
            state.update_available = message
            console.log(`Setting update status to ${message}`)
            localStorage.setItem('update_available', message)
        },
        set_speedydelivery_agency_selected_caregiver(state, cg_user_name) {
            console.log(`Inside set_speedydelivery_agency_selected_caregiver with ${cg_user_name}`)
            state.speedydelivery.agency_selected_caregiver = cg_user_name
            console.log(`Checking the set.....: ${state.speedydelivery.agency_selected_caregiver}`)
        },
        set_speedydelivery_agency_selected_caregiver_pname(state, cg_user_name) {
            state.speedydelivery.agency_selected_caregiver_pname = cg_user_name
        },
        set_speedydelivery_current_caregiver_profile_image(state, image) {
            state.speedydelivery.current_profile_image = image
        },
        set_spinner_state(state, message) {
            state.spinner_up = message
        },
        set_anon_uuid(state, message) {
            state.anon_uuid = message
        },
        update_rank_cache(state, message) {
            //message = [tmh_user_name, org_name, rank_str]
            let rc_json = localStorage.getItem('rank_cache')
            let rc
            if (rc_json === null) {
                rc = {}
            } else {
                rc = JSON.parse(rc_json)
            }
            let ten_minutes = 1000 * 60 * 10
            let expires = Date.now() + ten_minutes
            let user = message[0]
            let org = message[1]
            let rank = message[2]
            let key = JSON.stringify([user, org])
            let value = JSON.stringify([rank, expires])
            rc[key] = value
            localStorage.setItem('rank_cache', JSON.stringify(rc))

        },
        outside_set_inner_route(state, message) {
            console.log(`Setting inner route as ${message}`)
            state.outside.inner_route = message
        },
        set_main_forum_available_sets(state, message) {
            // console.log(`Setting set_main_forum_thread_count: ${message}`)
            state.forums.main_forum.page_count = message
        },
        spyglass_set_all_agency_ads(state, message) {
            if (Object.prototype.toString.call(message) === '[object Array]') {
                //pass
            } else {
                console.log("Ads collection must be an array")
                return null
            }
            state.spyglass.schedule_ads.ads = message
        },
        spyglass_agency_landing_public_set_agency_name(state, message) {
            // Set the name of the agency that published the ad that was clicked
            state.spyglass.current_ad_agency = message
        },
        spyglass_unique_user_session_id(state, message) {
            // Set the name of the agency that published the ad that was clicked
            state.spyglass.user_session_id = message
        },
        find_a_caregiver_user_session_id(state, message) {
            // Set the name of the agency that published the ad that was clicked
            state.chat.user_session_id = message
        },
        universal_user_session_id(state, message) {
            // Set the name of the agency that published the ad that was clicked
            state.chat.user_session_id = message
        },
        spyglass_agency_landing_set_ready_to_save(state, message) {
            // console.log(`Setting headline as ${message}`)
            state.spyglass.agency_landing.ready_to_save = message
        },
        spyglass_agency_landing_set_phone(state, message) {
            // console.log(`Setting headline as ${message}`)
            state.spyglass.agency_landing.phone_number = message
        },
        spyglass_set_agency_landing_headline(state, message) {
            // console.log(`Setting headline as ${message}`)
            state.spyglass.agency_landing.headline = message
        },
        spyglass_set_agency_landing_body(state, message) {
            // console.log(`Setting body as ${message}`)
            state.spyglass.agency_landing.body = message
        },
        spyglass_set_agency_landing_logo(state, message) {
            console.log(`Setting logo.`)
            state.spyglass.agency_landing.logo = message
        },
        spyglass_add_ad_to_ads(state, message) {
            state.spyglass.schedule_ads.ads.push(message)
        },
        spyglass_set_published_to_true(state) {
            state.spyglass.agency_landing.preview_published = true
        },
        spyglass_set_published_to_false(state) {
            state.spyglass.agency_landing.preview_published = false
        },

        set_activate_log_out(state, message) {
            state.activate_log_out = message
        },

        set_break_even_values(state, message) {
            state.freetime.break_even = message
        },
        set_assoc_invitation_code(state, message) {
            let ts = Date.now().toString()
            state.assoc_invitations[message.toString()] = ts
        },
        set_freetime_agency_message(state, message) {
            state.freetime.reports.agency_message = message
        },
        set_agency_logo(state, message) {
            // console.log("setting the agency logo with ....")
            // console.log(message['image'])
            state.agency_logos[message['agency']] = message['image']
        },
        increment_no_scroll(state, caller_id) {
            state.no_scroll_order[caller_id] = true
            // console.log(`BACKER WITH ID ${caller_id} ADDED TO STATE`)
        },
        decrement_no_scroll(state, caller_id) {
            delete state.no_scroll_order[caller_id];
            // console.log(`BACKER WITH ID ${caller_id} DELETED`)
        },
        set_profile_image(state, message) {
            state.personal_profile.image_str = message
        },
        set_profile_text(state, message) {
            state.personal_profile.profile_text = message
        },
        set_agency_profile_logo(state, message) {
            state.agency_profile.image_str = message
        },
        set_agency_profile_text(state, message) {
            state.agency_profile.profile_text = message
        },
        set_mobile_state(state, message) {
            state.mobile_state = message
        },
        freetime_break_even_multiplier(state, message) {
            state.freetime.break_even.multiplier = message
        },
        freetime_break_even_funded_amount(state, message) {
            state.freetime.break_even.funded_amount = message
        },
        freetime_break_even_highest_bonus(state, message) {
            state.freetime.break_even.highest_bonus = message
        },
        freetime_break_even_break_even_hours(state, message) {
            state.freetime.break_even.break_even_hours = message
        },
        freetime_break_even_highest_rate_hours(state, message) {
            state.freetime.break_even.highest_rate_hours = message
        },
        freetime_break_even_highest_pay_rate(state, message) {
            state.freetime.break_even.highest_pay_rate = message
        },
        freetime_break_even_base_pay(state, message) {
            state.freetime.break_even.avg_base_pay = message
        },
        freetime_break_even_avg_bill_rate(state, message) {
            state.freetime.break_even.avg_bill_rate = message
        },
        freetime_break_even_base_hours(state, message) {
            state.freetime.break_even.base_hours = message
        },
        freetime_break_even_payroll_burden(state, message) {
            state.freetime.break_even.payroll_burden = message
        },

        mother_pda_update_all_pdas_list(state, message) {
            state.mother_pda.states.all_pdas = message
        },
        mother_pda_update_candidate_already_exists(state, message) {
            state.mother_pda.states.candidate_already_exists = message
        },
        mother_pda_update_user_exists(state, message) {
            state.mother_pda.states.user_exists = message
        },
        update_context_changing_route(state, message) {
            // console.log("Context changing route being changed to: ")
            // console.log(message)
            state.context_changing_route = message
        },
        setWindowWidth(state) {
            state.windowWidth = window.innerWidth;
        },

        analytics_enqueue(state, message) {
            a_restart(state)
            // console.log("in notification queue mutation")
            // console.log(JSON.stringify(state.notification_queue))
            let the_que = state.analytics_queue
            the_que.enqueue(message)
            let str_que = JSON.stringify(the_que)
            localStorage.setItem('analytics_queue', str_que)
        },
        analytics_clear() {
            localStorage.setItem('analytics_queue', '')
        },

        continue_enqueue(state, message_array) {
            // play_sound('simple_notification.mp3')
            // let text = message_array[0]
            // let message = message_array[1]
            play_sound('/simple_notification_short.ogg')
            if (message_array === null) {
                return null
            }
            try {
                if (state.continue_queue.peek() === message_array) {
                    //    THe same error is getting repeated. Do not add  to queue.
                    return null
                }
            } catch (error) {
                //    pass
            }
            c_restart(state)
            // console.log("in notification queue mutation")
            // console.log(state.notification_queue)
            let the_que = state.continue_queue
            the_que.enqueue(message_array)
            let str_que = JSON.stringify(the_que)
            localStorage.setItem('durable_continue_queue', str_que)
        },

        notification_enqueue(state, message) {
            // play_sound('simple_notification.mp3')
            play_sound('/simple_notification_short.ogg')
            if (message === null) {
                return null
            }
            try {
                if (state.notification_queue.peek() === message) {
                    //    THe same error is getting repeated. Do not add  to queue.
                    return null
                }
            } catch (error) {
                //    pass
            }
            n_restart(state)
            // console.log("in notification queue mutation")
            // console.log(state.notification_queue)
            let the_que = state.notification_queue
            the_que.enqueue(message)
            let str_que = JSON.stringify(the_que)
            localStorage.setItem('durable_notification_queue', str_que)
        },
        // notification_queue_clear(state){
        //      localStorage.setItem('durable_notification_queue', null)
        //      state.notification_queue = ""
        // },
        error_enqueue(state, message) {
            // console.log("inside error enqueue")
            // console.log(message)
            play_sound('/error.mp3')
            if (message === null) {
                return null
            }
            if (message === "ID Token Expired failure. Log in with valid credentials for a valid token." || message === "Your token is expired or defective. You are going to need to log in again.") {
                message = message + '<br><br> <a class="go_to_login" href="/login">Go to Login</a>'
            }
            try {
                if (state.error_queue.peek() === message) {
                    //    THe same error is getting repeated. Do not add  to queue.
                    return null
                }
            } catch (error) {
                //    pass
            }

            e_restart(state)
            // console.log("in notification queue mutation")
            // console.log(state.notification_queue)
            let the_que = state.error_queue
            the_que.enqueue(message)
            let str_que = JSON.stringify(the_que)
            localStorage.setItem('durable_error_queue', str_que)
        },

        reset_current_error_message(state) {
            state.current_error_message = ''
        },
        // save_current_error_message(state, message) {
        //     if (message === "ID Token Expired failure. Log in with valid credentials for a valid token.") {
        //         message = message + '<br><br> <a href="/login">Go to Login</a>'
        //     }
        //     state.current_error_message = message
        //     localStorage.setItem('durable_error_message', message)
        // },

        save_latest_route(state, route) {
            state.latest_route = route
            localStorage.setItem('dev_latest_route', route)

        },
        clear_route_history() {
            localStorage.setItem("route_history", '{}')
        },
        save_route_history(state, store_item) {
            // console.log(`inside route history saver in store with ${JSON.stringify(store_item)}`)
            let current_history_str = localStorage.getItem('route_history')
            let current_history = JSON.parse(current_history_str)
            let url = store_item['path']
            let new_history = store_item['history']

            // let found = false
            for (const old_url in current_history) {
                if (old_url === url) {
                    // found = true
                    return
                } else {
                    //keep looking
                }
            }
            //if you made it here, then the url was not found in the history object.
            // console.log("the url was not already in the store")
            // console.log("what is current history?")
            // console.log(JSON.stringify(current_history))
            current_history[url] = new_history
            let new_current_history_str = JSON.stringify(current_history)
            localStorage.setItem('route_history', new_current_history_str)
        },

        save_socket_connection(state, conn) {
            state.socket_connection = conn
        },
        save_exclusions_at_server(state, status) {
            state.freetime.states.exclusions_at_server = status
        },
        save_match_at_server(state, status) {
            state.freetime.states.match_at_server = status
        },
        set_next_route_component_object(state, type) {
            state.route_component = type
        },


        update_forum(state, forum_data) {
            // This is used to add new thread and post content to the store.
            // console.log("inside increment_universal_page_update in vuex")

            //These are both lists of objects
            let threads = forum_data['data']['threads']
            let posts = forum_data['data']['posts']
            //Forum is a string
            let forum = forum_data['forum']

            let the_forum_object = null
            let old_threads = null
            let old_posts = null
            // console.log("So what got delivered to update forum?")
            // console.log(JSON.stringify(forum_data))
            // console.log(forum_data)


            // console.log("Current state of update forums")
            // console.log(`Threads: ${threads}`)
            // console.log(`Posts: ${posts}`)
            // console.log(`Forum: ${forum}`)
            // console.log(`old_threads: ${old_threads}`)
            // console.log(`old_posts: ${old_posts}`)

            if (state.forums[forum]) {
                the_forum_object = state.forums[forum]
            } else {
                the_forum_object = {
                    'threads': [],
                    'posts': [],
                }
            }


            if (threads) {

                old_threads = the_forum_object['threads']
                // console.log('These are the old threads...')
                // console.log(old_threads)


                for (const index in threads) {
                    // console.log(`${index}: ${object[index]}`);
                    let new_thread = threads[index]
                    // console.log('This is the new thread landing...')
                    // console.log(new_thread)
                    // console.log(JSON.stringify(new_thread))
                    //
                    // console.log(new_thread.sticky)
                    // console.log(new_thread.thread_title)
                    // console.log(new_thread.sticky === true)
                    // console.log(typeof new_thread.sticky)
                    // old_threads.unshift(new_thread)
                    old_threads.push(new_thread)
                }
                // let outset = getUniqueListBy(old_threads, 'thread_id')
                // outset = sort_stickies_and_regs(old_threads)
                // the_forum_object['threads'] = outset
            }

            if (posts) {
                old_posts = the_forum_object['posts']

                for (const index in posts) {
                    // console.log(`${index}: ${object[index]}`);
                    let new_post = posts[index]
                    old_posts.push(new_post)
                }

                // old_posts.sort(compare_timestamp);
                // old_posts = getUniqueListBy(old_posts, 'post_id')
                // old_posts.sort(compare_timestamp);
                // the_forum_object['posts'] = old_posts

            }

            let outset = getUniqueListBy(old_threads, 'thread_id')
            outset = sort_stickies_and_regs(old_threads)
            the_forum_object['threads'] = outset
            let some_posts = getUniqueListBy(old_posts, 'post_id')
            some_posts.sort(compare_timestamp);
            the_forum_object['posts'] = some_posts

            // the_forum_object['threads'] = old_threads
            // the_forum_object['posts'] = old_posts
            // state.forums[forum] = the_forum_object
            // console.log("Here is the current forum object in string and object forms after update")
            // console.log(JSON.stringify(state.forums))
            // console.log(state.forums)

        },


        get_forum_data(state, forum) {
            let the_forum = state.forums[forum]
            // console.log("Inside get forum data")
            // console.log(JSON.stringify(the_forum))

            if (!the_forum) {
                return {
                    'threads': [],
                    'posts': []
                }
            } else {
                return the_forum
            }
        },


        increment_universal_page_update(state, new_status) {
            // console.log("inside increment_universal_page_update in vuex")
            let old_count = state.universal_page_update

            if (new_status !== true && new_status !== false) {
                new_status = true
            }
            if (new_status === true) {
                old_count++
            }

            // console.log(`updating hb dropbox status counter with ${old_count}`)
            state.universal_page_update = old_count
        }
        ,
        freetime_dropbox_indb_update(state, new_status) {

            let old_count = state.freetime.dropbox.indb_update

            if (new_status !== true && new_status !== false) {
                new_status = true
            }
            if (new_status === true) {
                old_count++
            }

            // console.log(`updating hb dropbox status counter with ${old_count}`)
            state.freetime.dropbox.indb_update = old_count
        }
        ,
        mother_pda_dropbox_update_against_backend(state, new_status) {
            // console.log("In mother_pda_dropbox_update_against_backend")
            let old_count = state.mother_pda.dropbox.backend_update
            // console.log(`old count is ${old_count}`)
            let new_count = update_counter(old_count, new_status)
            // console.log(`new count is ${new_count}`)
            state.mother_pda.dropbox.backend_update = new_count
        }
        ,
        freetime_dropbox_history_report_update(state, new_status) {
            let old_count = state.freetime.dropbox.history_reports_update

            if (new_status !== true && new_status !== false) {
                new_status = true
            }
            if (new_status === true) {
                old_count++
            }
            // console.log(`updating hb dropbox status counter with ${old_count}`)
            state.freetime.dropbox.history_reports_update = old_count
        }
        ,
        socket_connection_counter_increment(state, new_status) {
            let old_count = state.reestablish_socket_connection_counter

            if (new_status !== true && new_status !== false) {
                new_status = true
            }
            if (new_status === true) {
                old_count++
            }
            // console.log(`updating hb dropbox status counter with ${old_count}`)
            state.reestablish_socket_connection_counter = old_count
        },
        // freetime_dropbox_prime_socket_update(state, new_status) {
        //     let old_count = state.freetime.dropbox.prime_socket
        //
        //     if (new_status !== true && new_status !== false) {
        //         new_status = true
        //     }
        //     if (new_status === true) {
        //         old_count++
        //     }
        //     // console.log(`updating hb dropbox status counter with ${old_count}`)
        //     state.freetime.dropbox.prime_socket = old_count
        // }
        // ,

        freetime_add_reports(state, payload) {
            // Accepts entire two-part report as json, breaks down and saves separately.
            let report_json = payload[0]
            let stake = payload[1]
            let report_obj = JSON.parse(report_json)
            let summary = JSON.stringify(report_obj['summary'])
            let cg = JSON.stringify(report_obj['caregivers'])
            state.freetime.reports.summary[stake] = summary
            state.freetime.reports.caregivers[stake] = cg
        }
        ,

        clear_local_storage() {
            // console.log("Inside create initial defaults")
            localStorage.clear()
        }
        ,


        create_initial_defaults(state) {
            // console.log("Inside create initial defaults")
            let defs = {
                'route_history': '{}',
                'username': '',
            }
            // localStorage.setItem('refresh_token', "")
            // localStorage.setItem('id_token', "")

            for (const i in defs) {
                let this_one = localStorage.getItem(i)
                if (this_one === null) {
                    localStorage.setItem(i, defs[i])
                } else {
                    // console.log(`${i} is already defined as ${this_one}. Leaving as is.`)
                }
            }
            state.proposed_preamble = '[]'
        }
        ,

        save_file_speedydelivery_drivers_license(state, file) {
            state.speedydelivery.files.drivers_license = file
        },
        save_file_speedydelivery_car_insurance(state, file) {
            state.speedydelivery.files.car_insurance = file
        },
        save_file_speedydelivery_drug_test(state, file) {
            state.speedydelivery.files.drug_test = file
        },
        save_file_speedydelivery_covid_record(state, file) {
            state.speedydelivery.files.covid_record = file
        },


        save_file_freetime_data(state, file) {
            state.freetime.files.data = file
        }
        ,
        save_file_freetime_match(state, file) {
            state.freetime.files.match = file
        }
        ,
        save_file_freetime_exclude(state, file) {
            state.freetime.files.exclude = file
        }
        ,
        save_file_freetime_name_data(state, name) {
            state.freetime.file_names.data = name
        }
        ,
        save_file_freetime_name_match(state, name) {
            state.freetime.file_names.match = name
        }
        ,
        save_file_freetime_name_exclude(state, name) {
            state.freetime.file_names.exclude = name
        }
        ,
        save_file_freetime_exclude_template(state, file) {
            state.freetime.files.exclude_template = file
        }
        ,
        save_file_freetime_match_template(state, file) {
            state.freetime.files.match_template = file
        }
        ,
        save_name(state, name_string) {
            // state.username = name_string
            localStorage.setItem('username', name_string)
        }
        ,
        save_password(state, pw_string) {
            // state.username = name_string
            state.password = pw_string
        }
        ,
        save_attempted_reentry_route(state, route) {
            // state.username = name_string
            state.attempt_reentry_route = route
        }
        ,
        save_email(state, email_string) {
            state.email = email_string
        }
        ,
        save_phone(state, phone_string) {
            state.phone_number = phone_string
        }
        ,
        save_id_token(state, token_string) {
            localStorage.setItem('id_token', token_string);
        }
        ,
        save_refresh_token(state, token_string) {
            // state.refresh_token = token_string
            localStorage.setItem('refresh_token', token_string);
        }
        ,
        save_proposed_preamble(state, preamble_string) {
            state.proposed_preamble = preamble_string
            localStorage.setItem('dev_proposed_preamble', preamble_string)
        }
        ,

        populate_storage_from_history(state, new_url) {
            // console.log("inside populate_storage_from_history")
            let history
            let route_history = JSON.parse(localStorage.getItem('route_history'))
            for (const url in route_history) {
                if (url === new_url) {
                    // console.log("Found pre-existing route is history object.")
                    history = route_history[url]
                    state.latest_route = history['route']
                    state.current_preamble = history['route_preamble']
                    // state.proposed_preamble = history['proposed_preamble']
                    state.route_component = history['route_component']
                } else {
                    //pass
                }
            }
        }
        ,
    },

    actions: {
        // for_caregivers_get_show_section_status(context, section){
        //     console.log(`for_caregivers_get_show_section_status in store with ${section}`)
        //     if(section === 'freetime'){
        //          console.log(`Returning from store: ${context.state.for_caregivers.show_freetime_section}`)
        //         return context.state.for_caregivers.show_freetime_section
        //     }else if(section === 'spyglass'){
        //         console.log(`Returning from store: ${context.state.for_caregivers.show_spyglass_section}`)
        //         return context.state.for_caregivers.show_spyglass_section
        //     } else {
        //         console.log(`Returning from store: ${context.state.for_caregivers.show_speedydelivery_section}`)
        //         return context.state.for_caregivers.show_speedydelivery_section
        //     }
        // },
        get_cache_value(context, key) {
            // console.log(`Saving key ${key} and value ${value} for ${cache_time} seconds.`)

           // console.log(`^^^^^^^^^^^^^^^^^^^Inside get cached value`)
           try {
                let now_seconds = timestamp(true)
                let the_cache = context.state.main_cache
                let the_value_array = the_cache[key]
                let value = the_value_array[0]
                let exp = the_value_array[1]
                let expired = compare_timestamp(now_seconds, exp)
                if(expired === 1){
                    //value is expired.
                    // console.log(`^^^^^^^^^^^^^^^^^^^Deleting expired value under the key ${key}`)
                    delete  the_cache.key
                    context.state.main_cache = the_cache
                    return null
                    }else{
                        // console.log(`^^^^^^^^^^^^^^^^^^^^^^^^Returning value from Vuex cache for the key ${key}`)
                        // console.log("!!!!!!!!!^^^^^^^^^^The value gettintg returned from the cache is...")
                        // console.log(value)
                        return value
                    }
            } catch (error) {
                    // console.log(`^^^^^^^^^^^^^^^^^^^Did not find a cached value for the key ${key}`)
                    return null;

            }
        },

        get_current_speedydelivery_document(context, doc_type) {
            console.log(`Inside get current speedy document with doc type ${doc_type}`)
            console.log(context.state.speedydelivery.files[doc_type])
            return context.state.speedydelivery.files[doc_type]
        },

        get_user_badge_data(context, user) {
            //if badge data has expired, set data to {} and return, else return.
            // console.log("In get personal badge data....");
            // console.log("-> state.personal_badge_data", state.personal_badge_data);
            // console.log("hello world")

            let user_badge_data = context.state.badge_data[user]

            if (user_badge_data === undefined) {
                return {}
            } else {
                console.log(`User badge data for ${user} was not undefined.`, user_badge_data)
            }


            let expries_ts = user_badge_data['expires']
            // console.log("-> Badges expire at",state.personal_badge_data['expires'] );
            let now = timestamp(true)
            // console.log("-> now is...", now);
            if (now < expries_ts) {
                // console.log("-> Badges are not expired", );
                return user_badge_data
            } else {
                // console.log("-> Badges are expired", );
                user_badge_data = undefined
                return {}
            }
        },


        log_mount_time(context, url) {
            //accept mount or unmount time, get back duration is seconds on unmounts.

            let now = timestamp(true)
            let page_history_ts = context.state.page_log.durations[url]

            if (page_history_ts) {
                //Then we presume this is an unmount
                let duration = now - page_history_ts
                context.state.page_log.durations[url] = undefined
                return duration
            } else {
                //we assume this is a mount
                context.state.page_log.durations[url] = now
                return null
            }
        },

        remove_thread_from_forum(context, thread_data) {
            let thread_id = thread_data['thread_id']
            let forum_name = thread_data['forum_name']
            // console.log("inside thread removal code")
            // console.log(`Looking to remove id: ${thread_id}`)
            let forum_data = context.state.forums[forum_name]
            let threads = forum_data['threads']
            // console.log(threads)

            let filtered_threads = []
            for (const index in threads) {
                // console.log(`${index}: ${object[index]}`);
                let new_thread = threads[index]
                // console.log(`Thread ID: ${new_thread.thread_id}`)
                // console.log(`A match? ${thread_id === new_thread.thread_id}`)

                if (new_thread.thread_id !== thread_id) {
                    filtered_threads.push(new_thread)
                }
                // old_threads.unshift(new_thread)
                // old_threads.push(new_thread)
            }
            // filtered_threads.sort(compare_timestamp_reverse);
            // filtered_threads = getUniqueListBy(filtered_threads, 'thread_id')
            filtered_threads = getUniqueListBy(filtered_threads, 'thread_id')
            filtered_threads = sort_stickies_and_regs(filtered_threads)

            forum_data['threads'] = filtered_threads
            // console.log("here are the remaining threads after the filter.")
            // console.log(filtered_threads)

        },

        update_individual_thread_or_post(context, forum_data) {
            // This is used to edit either a pre-existing thread or post.
            //    forum_data will contain either 'thread' or 'post', not both.

            console.log('Inside update_individual_thread_or_post')
            console.log(forum_data)
            let updated_thread = forum_data['thread']
            let updated_post = forum_data['post']
            let forum = forum_data['forum']
            let old_objects

            let id_key_to_use
            let type_key_to_use
            let updated_object
            if (updated_thread) {
                id_key_to_use = 'thread_id'
                type_key_to_use = 'threads'
                updated_object = updated_thread
            } else if (updated_post) {
                id_key_to_use = 'post_id'
                type_key_to_use = 'posts'
                updated_object = updated_post
            } else {
                //    Something went wrong
                console.log("No thread or post found in data on thread/post update attempt.")
            }

            let updated_id = updated_object[id_key_to_use]
            if (context.state.forums[forum]) {

                old_objects = context.state.forums[forum][type_key_to_use]
            } else {
                // If this forum does not exist, then we cannot update its contents.
                return null
            }
            for (const index in old_objects) {
                // console.log(`${index}: ${object[index]}`);
                let this_object = old_objects[index]
                if (this_object[id_key_to_use] === updated_id) {
                    //We have found the old object to update.
                    console.log("We have found the old object to update")
                    for (const [key, value] of Object.entries(updated_object)) {
                        console.log(`${key}: ${value}`);
                        this_object[key] = value
                    }
                    //There can be only one thread updated at a time, so we are done
                    console.log(`Finished updating ${type_key_to_use} on id: ${updated_id}.`)
                    console.log(`Printintg ${type_key_to_use}`)
                    console.log(this_object)
                    break
                }
            }
            // old_objects.sort(compare_timestamp_reverse);
            // old_objects = getUniqueListBy(old_objects, 'thread_id')

            if (type_key_to_use === 'threads') {
                let outset = getUniqueListBy(old_objects, 'thread_id')
                outset = sort_stickies_and_regs(old_objects)
                context.state.forums[forum][type_key_to_use] = outset
            } else {
                old_objects.sort(compare_timestamp_reverse);
                context.state.forums[forum][type_key_to_use] = old_objects
            }
        },

        set_personal_badge_data(context, new_badge_data) {
            //Create timestamp of one hour into the future

            new_badge_data['expires'] = timestamp(true) + 3600

            //Replace the old data with the new data
            context.state.personal_badge_data = new_badge_data
        },

        set_user_badge_data(context, new_user_badge_data_list) {
            //Create timestamp of one hour into the future
            let user = new_user_badge_data_list[0]
            let new_user_badge_data = new_user_badge_data_list[1]
            let all_user_badge_data = context.state.badge_data
            new_user_badge_data['expires'] = timestamp(true) + 3600
            all_user_badge_data[user] = new_user_badge_data

            //Replace the old data with the new data
            context.state.badge_data = all_user_badge_data
        },

        set_spyglass_agency_landing_public_logo(context, name_logo) {
            // console.log("Checkpoint 5")
            let agency = name_logo['agency']
            let logo = name_logo['logo']
            // console.log(agency, logo)
            let agency_data = context.state.spyglass.agency_landing_public_cache[agency]

            // console.log("Checkpoint 6")
            if (agency_data === undefined) {
                // console.log("Checkpoint 7")
                agency_data = {}
            }

            agency_data['logo'] = logo
            agency_data['name'] = agency
            context.state.spyglass.agency_landing_public_cache[agency] = agency_data
            // console.log("Checkpoint 9")
            // console.log(context.state.spyglass.agency_landing_public_cache[agency])

        },


        get_spyglass_agency_landing_public_logo(context, name) {
            // console.log("Checkpoint 8")
            let agency_data = context.state.spyglass.agency_landing_public_cache[name]
            // console.log(agency_data)

            if (agency_data === undefined) {
                return null
            } else {
                return agency_data['logo']
            }
        },

        get_spyglass_agency_landing_public_data(context, name) {
            // console.log("Checkpoint 8")
            let agency_data = context.state.spyglass.agency_landing_public_cache[name]
            // console.log(agency_data)

            if (agency_data === undefined) {
                return {}
            } else {
                return agency_data
            }
        },

        // get_spyglass_agency_landing_public_data_all(context) {
        //     // console.log("Checkpoint 8")
        //     let agency_data = context.state.spyglass.agency_landing_public_cache
        //     // console.log(agency_data)
        //
        //     if (agency_data === undefined) {
        //         return {}
        //     } else {
        //         return agency_data
        //     }
        // },
        set_spyglass_agency_landing_public_data_key(context, agency_key_value) {
            // console.log("Checkpoint 5")
            let agency = agency_key_value['agency']
            let key = agency_key_value['key']
            let value = agency_key_value['value']

            // console.log(agency, logo)
            let agency_data = context.state.spyglass.agency_landing_public_cache[agency]

            // console.log("Checkpoint 6")
            if (agency_data === undefined) {
                // console.log("Checkpoint 7")
                agency_data = {}
            }

            agency_data[key] = value
            context.state.spyglass.agency_landing_public_cache[agency] = agency_data
            // console.log("Checkpoint 9")
            // console.log(context.state.spyglass.agency_landing_public_cache[agency])

        },

        set_spyglass_agency_landing_public_data_all(context, agency_value) {
            // console.log("Checkpoint 5")
            let agency = agency_value['agency']
            let value = agency_value['value'] //a dict
            context.state.spyglass.agency_landing_public_cache[agency] = value
        },


        stack_proposed(context, preamble_segment_array) {
            // console.log("inside stack proposed ACTION")

            let clone_current_preamble = JSON.parse(JSON.stringify(context.getters.find_current_preamble))
            // console.log("The preamble as it currently stands is...")
            // console.log(JSON.stringify(clone_current_preamble))
            let proposed_envelope = preamble_segment_array[0]
            let last_item = clone_current_preamble.pop()
            if (last_item === undefined) {
                // The current preamble is an empty array
                clone_current_preamble.push(preamble_segment_array)
                let str_clone_current_preamble = JSON.stringify(clone_current_preamble)
                context.commit("save_proposed_preamble", str_clone_current_preamble)
            } else {
                let last_envelope = last_item[0]
                if (proposed_envelope === last_envelope) {
                    //    this is a like-like exchange. Replace the old segment with the new.
                    clone_current_preamble.push(preamble_segment_array)
                } else {
                    //this is a new type. Add it to the preamable
                    clone_current_preamble.push(last_item)
                    clone_current_preamble.push(preamble_segment_array)
                }
                // console.log("Just pushed a new segment onto the proposed preamble...")
                // console.log(JSON.stringify(clone_current_preamble))
                let str_clone_current_preamble = JSON.stringify(clone_current_preamble)
                // console.log("Save the proposed preamble.")
                context.commit("save_proposed_preamble", str_clone_current_preamble)
            }


        },
        continue_dequeue(context) {
            let que = context.state.continue_queue
            let res = que.dequeue()
            let que_str = JSON.stringify(que)
            localStorage.setItem('durable_continue_queue', que_str)
            return res
        },
        notification_dequeue(context) {
            let que = context.state.notification_queue
            let res = que.dequeue()
            let que_str = JSON.stringify(que)
            localStorage.setItem('durable_notification_queue', que_str)
            return res
        },
        error_dequeue(context) {
            let que = context.state.error_queue
            let res = que.dequeue()
            let que_str = JSON.stringify(que)
            localStorage.setItem('durable_error_queue', que_str)
            return res
        },
        notification_restart(state) {
            n_restart(state)
        },
        continue_restart(state) {
            n_restart(state)
        },
        error_restart(state) {
            e_restart(state)
        },

    },

    modules: {},

    getters: {
        get_safari_store(state) {
            return state.safari_context
        },
        get_current_vortex_agency(state){
            return state.vortex.current_agency
        },
         get_current_vortex_searcher_data(state){
            return state.vortex.current_searcher
        },
         get_current_vortex_collected_data(state){
            return state.vortex.collected_data
        },
        get_vortex_current_state(state){
            return state.vortex
        },

        get_pname_to_tmhname_map(state) {
            return state.pname_to_tmhname_map
        },
        get_speedydelivery_no_doc_caregivers(state) {
            return state.speedydelivery.no_doc_caregivers
        },
        get_speedydelivery_agency_selected_caregiver(state) {
            console.log(`Getting the sd agency selected cg from the store with ${state.speedydelivery.agency_selected_caregiver}`)
            return state.speedydelivery.agency_selected_caregiver
        },
        get_speedydelivery_agency_selected_caregiver_pname(state) {
            return state.speedydelivery.agency_selected_caregiver_pname
        },
        get_speedydelivery_current_profile_image(state) {
            return state.speedydelivery.current_profile_image
        },
        get_speedydelivery_drivers_license(state) {
            return state.speedydelivery.files.drivers_license
        },
        get_speedydelivery_car_insurance(state) {
            return state.speedydelivery.files.car_insurance
        },
        get_speedydelivery_drug_test(state) {
            return state.speedydelivery.files.drug_test
        },
        get_speedydelivery_covid_record(state) {
            return state.speedydelivery.files.covid_record
        },

        // get_personal_badge_data(state) {
        //     //if badge data has expired, set data to {} and return, else return.
        //     // console.log("In get personal badge data....");
        //     // console.log("-> state.personal_badge_data", state.personal_badge_data);
        //     // console.log("hello world")
        //     if (state.personal_badge_data === null) {
        //         // console.log("-Personal Badge Data was blank");
        //         return {}
        //     } else {
        //         // console.log("There was a badge value.");
        //     }
        //
        //     let expries_ts = state.personal_badge_data['expires']
        //     // console.log("-> Badges expire at",state.personal_badge_data['expires'] );
        //     let now = timestamp(true)
        //     // console.log("-> now is...", now);
        //     if (now < expries_ts) {
        //         // console.log("-> Badges are not expired", );
        //         return state.personal_badge_data
        //     } else {
        //         // console.log("-> Badges are expired", );
        //         state.personal_badge_data = null
        //         return state.personal_badge_data
        //     }
        // },

        get_for_agencies_carousel_quotes(state) {
            return state.carousel_quotes['for_agencies']
        },
        get_for_caregivers_carousel_quotes(state) {
            return state.carousel_quotes['for_caregivers']
        },
        get_home_carousel_quotes(state) {
            return state.carousel_quotes['home']
        },

        get_anon_uuid(state) {
            if (state.anon_uuid === "") {
                state.anon_uuid = get_uuid()
            }
            return state.anon_uuid
        },
        get_spinner_state(state) {
            // console.log("Checkpoint 8")
            return state.spinner_up
        },
        get_main_forum_available_sets(state) {
            return state.forums.main_forum.page_count
        },
        get_main_forum_block_size(state) {
            return state.forums.main_forum.block_size
        },

        get_main_forum_data(state) {
            return state.forums.main_forum
        },

        get_for_caregivers_active_section(state) {
            if (state.for_caregivers.show_freetime_section === true) {
                return 'freetime'
            } else if (state.for_caregivers.show_spyglass_section === true) {
                return 'spyglass'
            } else {
                return 'speedydelivery'
            }
        },
        get_for_agencies_active_section(state) {
            if (state.for_agencies.show_freetime_section === true) {
                return 'freetime'
            } else if (state.for_agencies.show_spyglass_section === true) {
                return 'spyglass'
            } else {
                return 'speedydelivery'
            }
        },

        get_outside_inner_route(state) {
            // console.log("Checkpoint 8")
            let inner_route = state.outside.inner_route
            // console.log(`Inner route from vuex is ${inner_route}`)

            if (inner_route === undefined) {
                return null
            } else {
                return inner_route
            }
        },
        update_status(state) {
            if (state.update_available !== 'unknown') {
                return state.update_available
            } else {
                return localStorage.getItem('update_available')
            }

        },

        get_rank_cache() {
            return JSON.parse(localStorage.getItem('rank_cache'))
        },
        get_activate_log_out(state) {
            return state.activate_log_out
        },
        reentry_route(state) {
            return state.attempt_reentry_route
        },
        assoc_invitation_codes(state) {
            return state.assoc_invitations
        },

        get_freetime_agency_message(state) {
            // console.log("in store at get_freetime_agency_message")
            // console.log(`${state.freetime.reports.agency_message}`)
            return state.freetime.reports.agency_message
        },
        get_agency_logos(state) {
            return state.agency_logos
        },

        get_spyglass_agency_landing_public_data_all(state) {
            // console.log("Checkpoint 8")
            let agency_data = state.spyglass.agency_landing_public_cache
            // console.log(agency_data)

            if (agency_data === undefined) {
                return {}
            } else {
                return agency_data
            }
        },

        no_scroll_state(state) {

            // console.log("Printing scroll lock state")
            // for (const property in state.no_scroll_order) {
            //     console.log(`${property}: ${state.no_scroll_order[property]}`)
            // }
            // console.log("scroll state length is")
            // console.log(Object.keys(state.no_scroll_order).length)

            for (const property in state.no_scroll_order) {
                if (state.no_scroll_order[property] === true) {
                    return true
                }
            }
            return false
        },
        get_profile_image(state) {
            return state.personal_profile.image_str
        },
        get_profile_text(state) {
            return state.personal_profile.profile_text
        },
        get_agency_profile_logo(state) {
            return state.agency_profile.image_str
        },
        get_agency_profile_text(state) {
            return state.agency_profile.profile_text
        },
        get_mobile_state(state) {
            return state.mobile_state
        },
        spyglass_agency_landing_get_ready_to_save(state) {
            // console.log(`Setting headline as ${message}`)
            return state.spyglass.agency_landing.ready_to_save
        },
        spyglass_agency_phone(state) {
            // console.log(`Setting headline as ${message}`)
            return state.spyglass.agency_landing.phone_number
        },
        spyglass_agency_landing_public_get_agency_name(state) {
            // console.log(`Setting headline as ${message}`)
            return state.spyglass.current_ad_agency
        },
        // spyglass_agency_landing_public_get_agency_logos(state) {
        //     // console.log(`Setting headline as ${message}`)
        //     return state.spyglass.agency_landing_public
        // },

        spyglass_get_agency_landing_body(state) {
            return state.spyglass.agency_landing.body
        },
        spyglass_get_preview_published_status(state) {
            return state.spyglass.agency_landing.preview_published
        },
        spyglass_get_agency_landing_headline(state) {
            // console.log(`get the headline ${state.spyglass.agency_landing.headline}`)
            // console.log(`get the headline ${state.spyglass}`)

            return state.spyglass.agency_landing.headline
        },
        spyglass_get_agency_landing_logo(state) {
            return state.spyglass.agency_landing.logo
        },
        spyglass_preview_is_available(state) {
            // console.log(`In spyglass_preview_is_available`)
            let logo = state.spyglass.agency_landing.logo
            // console.log(`Logo is: ${logo}`)
            let hl = state.spyglass.agency_landing.headline
            // console.log(`headline is: ${hl}`)
            let body = state.spyglass.agency_landing.body
            // console.log(`body is: ${body}`)
            let good_hl = hl.length >= 10
            let good_body = body.length >= 10
            // let published = state.spyglass.agency_landing.preview_published
            if (logo && good_hl && good_body) {
                console.log("Preview IS available")
                return true
            } else {
                console.log("Preview NOT available")
                console.log(good_hl)
                console.log(good_body)

                return false
            }
        },

        get_freetime_breakeven_values(state) {
            return state.freetime.break_even
        },
        spyglass_get_ads(state) {
            return state.spyglass.schedule_ads.ads
        },

        get_freetime_breakeven_multiplier(state) {
            try {
                return parseFloat(state.freetime.break_even.multiplier)
            } catch (e) {
                return "None"
            }
        },
        get_freetime_breakeven_funded_amount(state) {
            try {
                return parseFloat(state.freetime.break_even.funded_amount)
            } catch (e) {
                return "None"
            }
        },
        get_freetime_breakeven_highest_bonus(state) {
            try {
                return parseFloat(state.freetime.break_even.highest_bonus)
            } catch (e) {
                return "None"
            }

        },
        get_freetime_breakeven_highest_pay_rate(state) {
            // console.log("in get break even hours in vuex store")
            let r = state.freetime.break_even.highest_pay_rate
            // console.log(`Current break even value is ${r}`)
            if (r === "") {
                return ""
            } else {
                return parseFloat(state.freetime.break_even.highest_pay_rate)
            }
        },
        get_freetime_breakeven_highest_rate_hours(state) {
            // console.log("in get break even hours in vuex store")
            let r = state.freetime.break_even.highest_rate_hours
            // console.log(`Current break even value is ${r}`)
            if (r === "") {
                return ""
            } else {
                return parseFloat(state.freetime.break_even.highest_rate_hours)
            }
        },
        get_freetime_breakeven_break_even_hours(state) {
            // console.log("in get break even hours in vuex store")
            let r = state.freetime.break_even.break_even_hours
            // console.log(`Current break even value is ${r}`)
            if (r === "") {
                return ""
            } else {
                return parseFloat(state.freetime.break_even.break_even_hours)
            }
        },
        get_freetime_breakeven_avg_bill_rate(state) {
            // console.log("in get base bill rate vuex store")
            let r = state.freetime.break_even.avg_bill_rate
            // console.log(`Vuex value is ${r} and isNaN is ${isNaN(r)} and type is ${typeof r}`)
            if (r === "") {
                // console.log("vuex value is empty string")
                return ""

            } else {
                return parseFloat(state.freetime.break_even.avg_bill_rate)
            }
        },
        get_freetime_breakeven_avg_base_pay(state) {
            let r = state.freetime.break_even.avg_base_pay
            if (r === "") {
                return ""
            } else {
                return parseFloat(state.freetime.break_even.avg_base_pay)
            }
        },
        get_freetime_breakeven_payroll_burden(state) {
            let r = state.freetime.break_even.payroll_burden
            if (r === "") {
                return ""
            } else {
                return parseFloat(state.freetime.break_even.payroll_burden)
            }
        },
        get_freetime_breakeven_base_hours(state) {
            let r = state.freetime.break_even.base_hours
            if (r === "") {
                return ""
            } else {
                return parseFloat(state.freetime.break_even.base_hours)
            }
        },
        spyglass_user_session_id(state) {
            return state.spyglass.user_session_id
        },
        find_a_caregiver_user_session_id(state) {
            return state.chat.user_session_id
        },
        universal_user_session_id(state) {
            return state.chat.user_session_id
        },

        window_width(state) {
            return state.windowWidth
        },
        user_exists(state) {
            return state.mother_pda.states.user_exists
        },
        candidate_already_exists(state) {
            return state.mother_pda.states.candidate_already_exists
        },
        context_changing_route(state) {
            return state.context_changing_route
        },
        continue_peek(state) {
            return state.continue_queue.peek()[0]
        },
        continue_peek_payload(state) {
            return state.continue_queue.peek()[1]
        },
        notification_peek(state) {
            return state.notification_queue.peek()
        },
        error_peek(state) {
            return state.error_queue.peek()
        },
        error_length(state) {
            try {
                return state.error_queue.length()
            } catch (error) {
                e_restart(state)
                return state.error_queue.length()
            }
        },

        continue_length(state) {
            try {
                return state.continue_queue.length()
            } catch (error) {
                c_restart(state)
                return state.continue_queue.length()
            }
        },

        notification_length(state) {
            try {
                return state.notification_queue.length()
            } catch (error) {
                n_restart(state)
                return state.notification_queue.length()
            }
        },
        find_current_proposed_preamble_sans_service(state, getters) {
            // console.log(`Inside find_current_proposed_preamble_sans_service with proposed_preamble_clone`)
            // return the preamble popping off the last
            // preamble segment in cases where the last
            // envelope is service or a 'div' segment

            let proposed_preamble_clone = JSON.parse(getters.proposed_preamble)
            // console.log(`proposed_preamble_clone:${proposed_preamble_clone}`)
            // console.log(`Inside find_current_proposed_preamble_sans_service with proposed_preamble_clone ${proposed_preamble_clone} `)
            let res = []
            for (let i = proposed_preamble_clone.length - 1; i >= 0; i--) {
                // console.log(proposed_preamble_clone[i]);
                let current_element = proposed_preamble_clone[i]
                // let last_element = current_element[preamble.length - 1]
                let current_envelope = current_element[0]
                if (current_envelope === 'div' || current_envelope === 'service') {
                    // console.log("found a div or service envelope in the element. Skipping.")
                } else {
                    res.push(current_element)
                }
            }
            let res_rev = res.reverse()
            // console.log("resulting service free preamble is")
            // console.log(res_rev)
            return res_rev
        },
        find_current_preamble_sans_service(state, getters) {


            // console.log(`Inside find_current_preamble_sans_service`)
            // return the preamble popping off the last
            // preamble segment in cases where the last
            // envelope is service or a 'div' segment

            let current_preamble_clone = JSON.parse(JSON.stringify(getters.find_current_preamble))
            // console.log(`current_preamble_clone:${current_preamble_clone}`)
            let res = []
            for (let i = current_preamble_clone.length - 1; i >= 0; i--) {
                // console.log(current_preamble_clone[i]);
                let current_element = current_preamble_clone[i]
                // let last_element = current_element[preamble.length - 1]
                let current_envelope = current_element[0]
                if (current_envelope === 'div' || current_envelope === 'service') {
                    // console.log("found a div or service envelope in the element. Skipping.")
                } else {
                    res.push(current_element)
                }
            }
            let res_rev = res.reverse()
            // console.log("resulting service free preamble is")
            // console.log(res_rev)
            return res_rev
        },

        find_current_preamble(state, getters) {
            // console.log("Calling find_current_preamble")
            let history = getters.route_history_obj

            // console.log("in find current preamble...")
            // console.log("latest route is")
            // console.log(getters.latest_route)

            let new_url = getters.latest_route
            // console.log(`The pulled latest route is ${new_url}`)


            for (const url in history) {
                if (url === new_url) {
                    let res_str = history[url]['route_preamble']
                    // console.log(`Found the route in the history object: ${new_url}`)
                    // console.log(res_str)
                    localStorage.setItem('dev_last_current_preamble', res_str)

                    //This next line is experimental
                    localStorage.setItem('dev_proposed_preamble', res_str)

                    return JSON.parse(res_str)
                } else {
                    //pass
                }
            }
            console.log("No route in history. Return proposed preamble...")
            console.log(getters.proposed_preamble)
            return JSON.parse(getters.proposed_preamble)
        },

        current_error_message(state) {
            let durable = localStorage.getItem('durable_error_message')
            if (state.current_error_message === '' && durable !== null) {
                state.current_error_message = durable
            }

            return state.current_error_message
        },

        latest_route(state) {
            if(state.latest_route){
                // console.log("Found the latest route in state...")
                // console.log(state.latest_route)
               return state.latest_route
            }else{
                return localStorage.getItem('dev_latest_route')
            }

        },
        route_history_obj() {
            let history_str = localStorage.getItem('route_history')
            return JSON.parse(history_str)
        },
        socket_connection(state) {
            return state.socket_connection
        },
        preamble_org_portion(state, getters) {
            let c_preamble = getters.find_current_preamble
            for (let index = c_preamble.length - 1; index >= 0; index--) {
                if (c_preamble[index][0] === 'org') {
                    // console.log("found an org portion of preamble as...")
                    let org_part = c_preamble.slice(0, index + 1)
                    // console.log(org_part)
                    return org_part
                } else {
                    //pass
                }
            }
            return []
        },
        preamble_service_portion(state, getters) {
            let c_preamble = getters.find_current_preamble
            for (let index = c_preamble.length - 1; index >= 0; index--) {
                if (c_preamble[index][0] === 'service') {
                    return c_preamble.slice(0, index + 1)
                } else {
                    //pass
                }
            }
            return []
        },
        org_context(state, getters) {
            let c_preamble = getters.find_current_preamble
            // console.log(`Current Preamble is ${c_preamble}`)

            for (let index = c_preamble.length - 1; index >= 0; index--) {
                if (c_preamble[index][0] === 'org') {
                    return c_preamble[index][1]
                } else {
                    //pass
                }
            }
            return ''
        },
        exclusions_at_server(state) {
            return state.freetime.states.exclusions_at_server;
        },
        match_at_server(state) {
            return state.freetime.states.match_at_server;
        },
        get_next_route_component(state) {
            // console.log("in get_next_route_component")
            let c_type_str = state.route_component
            // console.log(`next route component string is ${c_type_str}`)
            return state.route_components[c_type_str]
        },
        get_next_route_component_str(state) {
            // console.log("in get_next_route_component")
            return state.route_component
        },
        my_name() {
            // return state.username;
            return localStorage.getItem('username')
        },
        my_ip() {
            // return state.username;
            if (localStorage.getItem('ip')) {
                return localStorage.getItem('ip')
            } else {
                return "IP Unknown"
            }
        },
        my_phone(state) {
            return state.phone_number;
        },
        my_email(state) {
            return state.email;
        },
        my_password(state) {
            return state.password;
        },
        my_token() {
            return localStorage.getItem('id_token')
        },
        my_id_token() {

            return localStorage.getItem('id_token')
        },
        my_refresh_token() {
            return localStorage.getItem('refresh_token')
        },
        my_orgs() {
            return localStorage.getItem('my_orgs')
        },
        my_services() {
            return localStorage.getItem('my_services')
        },

        proposed_preamble(state) {
            // console.log("INside proposed preamble vuex...")
            if(state.proposed_preamble === "[]"){
                // console.log("There was NOT NOT NOT a proposed preable ind it was ... go with dev_proposed_preamble...")
                // console.log(state.proposed_preamble)
                // console.log(typeof state.proposed_preamble)
                // console.log("local dev proposed preamble is ...")
                // console.log(localStorage.getItem('dev_proposed_preamble'))

                if(localStorage.getItem('dev_proposed_preamble') === null){
                    return "[]"
                }else{
                    // console.log("dev_proposed_preamble used is...")
                    // console.log(localStorage.getItem('dev_proposed_preamble'))
                    return localStorage.getItem('dev_proposed_preamble')
                }

            }else{
                // console.log("There was a proposed preable ind it was ...")
                // console.log(state.proposed_preamble)
                // console.log(typeof state.proposed_preamble)
                return state.proposed_preamble
            }

        },
        last_url_context_proposed(state, getters) {
            //    this is the last non-service context in the premable
            let preamble_array = JSON.parse(getters.proposed_preamble)
            let looking = true
            while (looking) {
                let last_pair_array = preamble_array.pop()
                if (last_pair_array[0] !== 'service') {
                    looking = false
                    return last_pair_array[1]
                }
            }

        },


        last_context(state, getters) {
            let preamble_array = getters.find_current_preamble
            let last_pair_array = preamble_array.pop()
            try {
                return last_pair_array.pop()
            } catch (e) {
                //pass
            }

        },

        last_context_proposed(state, getters) {
            try {
                let preamble_array

                preamble_array = JSON.parse(getters.proposed_preamble)

                if (preamble_array === []) {
                    preamble_array = []
                }

                let last_pair_array = preamble_array.pop()
                return last_pair_array[1]
            } catch (error) {
                return "person"
            }
        },
        last_envelope_proposed(state, getters) {
            try {
                let preamble_array

                preamble_array = JSON.parse(getters.proposed_preamble)

                if (preamble_array === []) {
                    preamble_array = []
                }

                let last_pair_array = preamble_array.pop()
                return last_pair_array[0]
            } catch (error) {
                return "person"
            }
        },

        last_envelope(state, getters) {
            try {
                let preamble_array

                preamble_array = getters.find_current_preamble

                if (preamble_array === []) {
                    preamble_array = JSON.parse(state.getters.proposed_preamble)
                }

                let last_pair_array = preamble_array.pop()
                return last_pair_array[0]
            } catch (error) {
                return "person"
            }
        },
        universal_page_update(state) {
            return state.universal_page_update
        },

        get_socket_connection_status(state){
            return state.good_socket_connection
        },
         get_network_speed_status(state){
            return state.network_kbps
        },
         get_token_time(state){
            return state.token_time
        },


        good_socket_connection(state) {
            return state.good_socket_connection
        },

        network_speed(){
            return localStorage.measured_kbps
        },

        //  token_time(){
        //     return localStorage.time_until_token_exp
        // },

        socket_connection_counter(state) {
            return state.reestablish_socket_connection_counter
        },


        get_file_freetime_data(state) {
            return state.freetime.files.data
        },

        get_file_freetime_match(state) {
            return state.freetime.files.match
        },

        get_file_freetime_exclude(state) {
            return state.freetime.files.exclude
        },
        get_file_freetime_match_template(state) {
            return state.freetime.files.match_template
        },
        get_file_freetime_exclude_template(state) {
            return state.freetime.files.exclude_template
        },
        get_file_freetime_name_data(state) {
            return state.freetime.file_names.data
        },
        get_file_freetime_name_match(state) {
            return state.freetime.file_names.match
        },
        get_file_freetime_name_exclude(state) {
            return state.freetime.file_names.exclude
        },
        freetime_dropbox_indb_update_status(state) {
            return state.freetime.dropbox.indb_update
        },
        freetime_dropbox_history_report_status(state) {
            return state.freetime.dropbox.history_reports_update
        },
        // freetime_dropbox_prime_socket_status(state) {
        //     return state.freetime.dropbox.prime_socket
        // },
        mother_pda_controls_backend_update(state) {
            return state.mother_pda.dropbox.backend_update
        },
        mother_pda_all_pdas(state) {
            return state.mother_pda.states.all_pdas
        },
    }
})


const update_counter = (target_counter, new_status) => {
    if (new_status !== true && new_status !== false) {
        new_status = true
    }
    if (new_status === true) {
        target_counter++
    }
    return target_counter
}


const c_restart = state => {
    // console.log("inside notification restart action")
    let durable = localStorage.getItem('durable_continue_queue')

    // console.log("durable")
    // console.log(durable)
    // console.log("state")
    // console.log(state.notification_queue)
    if ((state.continue_queue === '' || state.continue_queue === undefined) &&
        durable !== null) {

        // console.log("reseting notification queue from durable")
        let reque = JSON.parse(durable)
        // console.log("reque")
        // console.log(reque)
        let que = new Queue()
        que.elements = reque['elements']
        state.continue_queue = que
        // console.log("state.continue_queue")
        // console.log(state.continue_queue)
        localStorage.setItem('durable_continue_queue', JSON.stringify(que))
    } else if ((state.continue_queue === undefined || state.continue_queue === '') &&
        durable === null) {
        // console.log("Creating brand new notification queue")
        let que = new Queue()
        state.continue_queue = que
        // que.enqueue(message)
        localStorage.setItem('durable_continue_queue', JSON.stringify(que))
    } else {
        //pass
    }
}


const n_restart = state => {
    // console.log("inside notification restart action")
    let durable = localStorage.getItem('durable_notification_queue')

    // console.log("durable")
    // console.log(durable)
    // console.log("state")
    // console.log(state.notification_queue)
    if ((state.notification_queue === '' || state.notification_queue === undefined) &&
        durable !== null) {

        // console.log("reseting notification queue from durable")
        let reque = JSON.parse(durable)
        // console.log("reque")
        // console.log(reque)
        let que = new Queue()
        que.elements = reque['elements']
        state.notification_queue = que
        // console.log("state.notification_queue")
        // console.log(state.notification_queue)
        localStorage.setItem('durable_notification_queue', JSON.stringify(que))
    } else if ((state.notification_queue === undefined || state.notification_queue === '') &&
        durable === null) {
        // console.log("Creating brand new notification queue")
        let que = new Queue()
        state.notification_queue = que
        // que.enqueue(message)
        localStorage.setItem('durable_notification_queue', JSON.stringify(que))
    } else {
        //pass
    }
}

const e_restart = state => {
    let durable = localStorage.getItem('durable_error_queue')
    if ((state.error_queue === '' || state.error_queue === undefined) &&
        durable !== null) {
        let reque = JSON.parse(durable)
        let que = new Queue()
        que.elements = reque['elements']
        state.error_queue = que
        localStorage.setItem('durable_error_queue', JSON.stringify(que))
    } else if ((state.error_queue === undefined || state.error_queue === '') &&
        durable === null) {
        let que = new Queue()
        state.error_queue = que
        localStorage.setItem('durable_error_queue', JSON.stringify(que))
    } else {
        //pass
    }
}

// const a_restart = state => {
//     let durable = localStorage.getItem('analytics_queue')
//     console.log("current durable analytics queue is: ")
//     console.log(durable)
//     console.log("current state analytics queue is: ")
//     console.log(state.analytics_queue)
//     if ((state.analytics_queue === '' || state.analytics_queue === undefined) &&
//         durable !== null) {
//          console.log("Inside option 1")
//         let reque = JSON.parse(durable)
//         let que = new Queue()
//         que.elements = reque['elements']
//         state.analytics_queue = que
//         localStorage.setItem('analytics_queue', JSON.stringify(que))
//     } else if ((state.analytics_queue === undefined || state.analytics_queue === '') &&
//         durable === null) {
//         console.log("Inside option 2")
//         let que = new Queue()
//         state.analytics_queue = que
//         localStorage.setItem('analytics_queue', JSON.stringify(que))
//     } else {
//         //pass
//     }
// }

const a_restart = state => {
    let durable = localStorage.getItem('analytics_queue')
    let que = new Queue()
    try {
        let reque = JSON.parse(durable)
        que.elements = reque['elements']
        state.analytics_queue = que
        localStorage.setItem('analytics_queue', JSON.stringify(que))
    } catch (error) {
        state.analytics_queue = que
        localStorage.setItem('analytics_queue', JSON.stringify(que))
    }
}