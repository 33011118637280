<template>
  <div class="core_c">
<!--  <h1>TEST</h1>-->
<!--  <h2>TEST</h2>-->
<!--  <h1 class="bg_lauren_gray">TEST</h1>-->
    <inside_banner
        mode="None"
        :owner_perm=false
        @agency_info_clicked="do_nothing"
        @invite_clicked="do_nothing"
        @promote_clicked="do_nothing"
    >
    </inside_banner>

    <basic_page_infrastructure
        @local_page_update="do_nothing"
    ></basic_page_infrastructure>

     <Suspense>
        <template #default>
          <PlusAdGuts></PlusAdGuts>
        </template>
        <template #fallback>
          <h2>Searching for schedules data...</h2>
        </template>
      </Suspense>

  </div>
    <outside_footer class="footer_c margin_t_50"></outside_footer>
</template>

<script>
/* eslint-disable */
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import inside_banner from "@/components/parts/inside_banner";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import outside_footer from "@/components/parts/outside_footer.vue";
import PlusAdGuts from "@/components/SpyGlass/SpyGlassPlus/PlusAdGuts.vue";

import {
  colors,
  scroll_to_top,
  image_name, be_main_address, parse_internal_json,
} from "@/library";
import 'flatpickr/dist/flatpickr.css';
import {mapGetters} from "vuex";
import axios from "axios";
// import people from "@/components/orgs/pda/people.vue";

export default {
  name: "SpyGlass Plus Ad",
  async mounted() {
    scroll_to_top()
    this.run_window_width_adjustments(this.windowWidth)
    // let ad_data = await this.get_ad_data()
    // console.log("Got back data on mount...")
    // console.log(ad_data)
    // this.ad_viewed(ad_data['agency'])

  },

  props: [],
  components: {
    // people,
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    PlusAdGuts,
    outside_footer
  },
  data() {
    return {
      // id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
      console.log(something)
    },


    get_ad_data(){
       let target_url = be_main_address('sg') + 'spyglass_plus_ad_data'
      // let target_url = 'http://192.168.1.10:8087/ft/get_good_subscribers'
      let data1 = {
        uuid: localStorage.getItem('spyglass_plus_ad')
      }

      axios.post(target_url, data1)
          .then((response) => {
            console.log('Ad response')
            // console.log(response['data'])
            // console.log(typeof response['data'])
            // let data_res = JSON.parse(response['data'])
            // console.log(data_res)
            // console.log(typeof data_res)
            // console.log(JSON.parse(response['data'])['data'])
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                 let parsed = parse_internal_json(response.data)
                 let message = parsed['message']
                store.commit('error_enqueue', message)
              } else {
                let parsed = parse_internal_json(response.data)
                this.the_data = parsed['data']

                // let message = parsed['message']
                console.log(this.the_data)
                return this.the_data
                // console.log(message)
                // store.commit('notification_enqueue', message)
              }
            } else {
              console.log("SERVER ERROR")
              store.commit('error_enqueue', "Sorry. The server is having to trouble at the moment.")
            }
          })
    },


    image_name(name) {
      return image_name(name)
    },

    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },

  },
  computed: {
    ...mapGetters([]),
    id() {
      return this.id_
    },

    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },


  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {

  .ad_desc{
    max-width: 260px;
  }

   .ad_row_item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ad_row_item span {
    margin-right: 10px;
    margin-left: 10px;
  }

   .material_icon {
    width: 30px;
    height: 40px;
    margin-right: 25px;
    margin-left: 10px;
  }

  h1 {
    color: white;
  }


  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }


}

@media (min-width: 280px) {
  .sd_logo {
    width: 200px
  }
}

@media (min-width: 600px) {
    .material_icon {
    width: 35px;
    height: 50px;
    margin-right: 25px;
  }


}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .ft_logo {
    width: 400px
  }
}

@media (min-width: 2000px) {
  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
  }


  .mfa_method p {
  }


}
</style>