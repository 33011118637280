<template>
  <div class="core_c flex_centered_column">

    <section class="flex_centered_start_column screen_block">


      <div class="flex_centered_row margin_b_30">
        <img @click="do_nothing" class="padding_tb_20 sd_logo cursor_ptr"
             src="@/assets/spyglass2_color.svg"
             alt="Spyglass Feature Logo">
      </div>

      <!--      <h3 class="text_align_center title ">SpyGlass Client Offer</h3>-->


      <div class="flex_left_aligned_centered_column bg_trad_ad_sms wid_100 padding_tb_40 margin_t_10">
        <div class="flex_centered_row">
          <h3 class="text_align_center title text_whitesmoke margin_b_40">SpyGlass Client Offer</h3>
        </div>


        <!--        <h5 class="text_align_center margin_tb_15">{{ the_data.headline }}</h5>-->
        <h5 class="wid_95 text_align_center margin_tb_15 text_whitesmoke">{{ the_data.headline }}</h5>

        <div class="flex_start_row ad_row_item">
          <img class="material_icon event_icon"
               :src="image_name('event_white.svg')"
               alt="Icon">
          <!--          <p class="font_weight_800 font_raleway">{{the_data.start_time}}</p>-->
          <div class="flex_centered_column">
            <p class="font_weight_800 font_raleway text_whitesmoke">{{ converted_start_time }}</p>
          </div>
        </div>

        <div class="flex_start_row ad_row_item">
          <img class="material_icon"
               :src="image_name('location_on_white.svg')"
               alt="Icon">
          <!--          <p class="font_weight_800 font_raleway text_whitesmoke">{{ the_data.neighborhood }}</p>-->
          <p class="font_weight_800 font_raleway text_whitesmoke">{{ the_data.neighborhood }}</p>
        </div>

        <div class="flex_start_row ad_row_item">
          <img class="material_icon"
               :src="image_name('description_white.svg')"
               alt="Icon">
          <!--          <div class="ad_desc font_raleway font_weight_800">{{ the_data.body }}</div>-->
          <div class="ad_desc font_raleway font_weight_800 text_whitesmoke">{{ the_data.body }}
          </div>
        </div>

        <div class="flex_start_row ad_row_item">

          <img class=" agency_logo"
               :src="image_name('heart_home_white.svg')"
               alt="Icon">
          <!--          <div class="ad_desc font_raleway font_weight_800">{{ the_data.body }}</div>-->
          <image_upload_widgit
              ref="upload_widgit"
              class=" "
              :image_value=the_data.agency_logo
              image_width="208"
              image_height="116"
              frame_text="Posting agency logo"
              event_name="save_pic"
              :owners_perm="false"
              @save_pic="do_nothing"
          ></image_upload_widgit>
        </div>

        <div class="flex_centered_row">
          <FreeTime_stamp_trad_ad_sms
              class="margin_lr_10 margin_t_40 "
              :base=base_pay_amount
              :fulltime_avg=full_time_avg
              :fulltime_high=full_time_high
          ></FreeTime_stamp_trad_ad_sms>
        </div>


        <div class="flex_centered_column">

          <h4 class="wid_75 margin_t_40 cta text_align_center">Just click the "interested" button below if you
            would like to be considered for this client.</h4>
          <basic_text_button
              class="padding_lr_6 padding_tb_6  margin_t_40 margin_b_40"
              :hover_color=colors.cyanic_blue
              :hover_text_color=colors.white
              :button_state=!interest_button_clicked
              :button_color="colors.trad_ad_button"
              :text_color=colors.new_primary_purple_deep
              :button_color_not_ready="colors.eli_gray"
              :button_not_ready_text_color="colors.pennie_gray"
              button_height="72px"
              button_width="240px"
              border_rad_pct="0"
              text="Interested!"
              text_size="24px"
              :bold="true"
              event_name="confirm_button_clicked"
              @confirm_button_clicked="interested_clicked"
              v-on:keyup.enter="interested_clicked"
          ></basic_text_button>
        </div>

      </div>


    </section>

  </div>
</template>

<script>
/* eslint-disable */
import basic_page_infrastructure from "@/components/parts/basic_page_infrastructure";
import inside_banner from "@/components/parts/inside_banner";
import spinner from "@/components/parts/spinner";
import basic_text_button from "@/components/parts/basic_text_button";
import store from "@/store";
import outside_footer from "@/components/parts/outside_footer.vue";
import FreeTime_stamp from "@/components/SpyGlass/FreeTime_stamp";
import FreeTime_stamp_trad_ad_sms from "@/components/SpyGlass/SpyGlassSMS/FreeTime_stamp_trad_ad_sms.vue";

import {
  colors,
  open_spinner, close_spinner,
  scroll_to_top,
  image_name, be_main_address, parse_internal_json,
} from "@/library";

import 'flatpickr/dist/flatpickr.css';
import {mapGetters} from "vuex";
import axios from "axios";
// import people from "@/components/orgs/pda/people.vue";
import image_upload_widgit from "@/components/parts/image_upload_widgit.vue";

export default {
  name: "SpyGlass Trad Ad Guts",
  async mounted() {
    await this.get_ad_data()

  },

  props: [],
  components: {
    image_upload_widgit,
    // people,
    spinner,
    basic_text_button,
    basic_page_infrastructure,
    inside_banner,
    outside_footer,
    FreeTime_stamp_trad_ad_sms
  },
  data() {
    return {
      // id_: "",
      spinner_up_: false,
      colors: colors,
      mobile_mode_x: true,
      interest_button_clicked_: false,
      the_data: {},
    }
  },
  methods: {
    do_nothing(something) {
      console.log(`do nothing with ${something}`)
      console.log(something)
    },

    ad_viewed(the_data) {
      let target_url = be_main_address('sg') + 'spyglass_trad_ad_viewed'
      console.log("Sending ad viewed data for....")
      console.log(the_data['cg_tmh_name']['tmh_name'])
      let data1 = {
        uuid: localStorage.getItem('spyglass_trad_ad'),
        agency: the_data['agency'],
        tmh_user: the_data['cg_tmh_name']['tmh_name']
      }
      console.log("Making backend call with...")
      console.log(data1)

      axios.post(target_url, data1)
          .then((response) => {
            console.log('View response')
            console.log(response['data'])
            console.log(typeof response['data'])
            let parsed = JSON.parse(response['data'])
            console.log(parsed)
            // console.log(typeof data_res)
            // console.log(JSON.parse(response['data'])['data'])
            if (response.statusText === "OK" || response.status === 200) {
              if (parsed['error'] === true) {
                // let parsed = parse_internal_json(response.data)
                let message = parsed['message']
                store.commit('error_enqueue', message)
              } else {
                // let parsed = parse_internal_json(response.data)
                // let message = parsed['message']
                // store.commit('notification_enqueue', message)

                // let message = parsed['message']
                // console.log(this.the_data)
                // console.log(message)
                // store.commit('notification_enqueue', message)
              }
            } else {
              console.log("SERVER ERROR")
              store.commit('error_enqueue', "Sorry. The server is having to trouble at the moment.")
            }
          })
    },


    interested_clicked() {
      let target_url = be_main_address('sg') + 'spyglass_trad_ad_interested'
      // let target_url = 'http://192.168.1.10:8087/ft/get_good_subscribers'
      let data1 = {
        uuid: localStorage.getItem('spyglass_trad_ad'),
        agency: this.the_data['agency'],
        tmh_user: this.the_data['cg_tmh_name']['tmh_name']
      }
      open_spinner()

      axios.post(target_url, data1)
          .then((response) => {
            console.log('View response')
            if (response.statusText === "OK" || response.status === 200) {
              if (response.data['error'] === true) {
                let parsed = parse_internal_json(response.data)
                let message = parsed['message']
                store.commit('error_enqueue', message)
              } else {
                let parsed = parse_internal_json(response.data)
                let message = parsed['message']
                store.commit('notification_enqueue', message)
                this.interest_button_clicked_ = true
              }
            } else {
              console.log("SERVER ERROR")
              store.commit('error_enqueue', "Sorry. The server is having to trouble at the moment.")
            }
          })
      close_spinner()
    },

    get_ad_data() {
      let target_url = be_main_address('sg') + 'spyglass_trad_ad_data'
      let data1 = {
        uuid: localStorage.getItem('spyglass_trad_ad')
      }

      open_spinner()
      axios.post(target_url, data1)
          .then((response) => {
            let data_res = response['data']['data']
            if (response.statusText === "OK" || response.status === 200) {
              if (response['data']['error'] === true) {
                console.log(response)
                let message = response['data']['message']
                store.commit('error_enqueue', message)
                return null
              } else {
                this.the_data = response['data']['data']
                return this.the_data
              }
            } else {
              console.log("SERVER ERROR")
              store.commit('error_enqueue', "Sorry. The server is having to trouble at the moment.")
              return null
            }
          }).then((the_data) => {
        if (the_data) {
          the_data['cg_tmh_name'] = JSON.parse(the_data['cg_tmh_name'])
          console.log("Here is the current state of the data before logging the View...")
          console.log(the_data)
          this.ad_viewed(the_data)
        }

      })
      close_spinner()
    },


    image_name(name) {
      return image_name(name)
    },

    run_window_width_adjustments(new_w) {

      if (new_w >= 280) {
        //do something
      }
      if (new_w >= 500) {
        //do something
      }
      if (new_w >= 500) {
        this.mobile_mode_x = false
      }
      if (new_w >= 2000) {
        //do something
      }
    },

    simple_route_prep(r) {
      let this_route = `/${r}`
      store.commit('save_latest_route', this_route)
      this.$router.push({path: this_route})
    },

  },
  computed: {
    ...mapGetters([]),
    id() {
      return this.id_
    },
    converted_start_time() {
      try{
        let [datePart, timePart] = this.the_data.start_time.split(",");
      // Trim any leading or trailing whitespace from the date part
      datePart = datePart.trim();
      return datePart;
      }catch(err){
        //pass
      }

    },
    interest_button_clicked() {
      console.log(`Current interest button clicked state: ${this.interest_button_clicked_}`)
      return this.interest_button_clicked_
    },
    base_pay_amount() {
      try {
        console.log("Getting base pay amount...")
        console.log(this.the_data['freetime_stats'].base_pay)
        return this.the_data['freetime_stats']['base_pay'].toFixed(2)
      } catch (err) {
        //pass
      }

    },


    full_time_avg() {
      try {
        // console.log("Getting base pay amount...")
        // console.log(this.the_data['freetime_stats'].base_pay)
        return this.the_data['freetime_stats']['fulltime_avg']
      } catch (err) {
        //pass
      }

    },

    full_time_high() {
      try {
        // console.log("Getting base pay amount...")
        // console.log(this.the_data['freetime_stats'].base_pay)
        return this.the_data['freetime_stats']['fulltime_high']
      } catch (err) {
        //pass
      }

    },


    spinner_up() {
      return store.getters.get_spinner_state
    },
    windowWidth() {
      return store.state.windowWidth;
    },
    mobile_mode() {
      return this.mobile_mode_x
    },


  },
  watch: {
    windowWidth(new_w) {
      this.run_window_width_adjustments(new_w)
    },
  },
}
</script>
<style scoped>
@import '../../../common.css';

@media (min-width: 100px) {


  .title {
    width: 80%
  }

  .cta {
    color: var(--trad-ad-cta-text)

  }

  .sd_logo {
    width: 100px
  }

  .ad_desc {
    max-width: 350px;
  }

  .ad_row_item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ad_row_item span {
    margin-right: 10px;
    margin-left: 10px;
  }

  .event_icon {

  }

  .agency_logo {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    margin-left: 10px;
  }

  .material_icon {
    width: 30px;
    height: 40px;
    margin-right: 25px;
    margin-left: 10px;
  }

  .money_icon {
    width: 30px;
    height: 30px;
    margin-right: 25px;
    margin-left: 10px;
  }

  h1 {
    color: white;
  }


  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 25px;
    text-align: center
  }


  .standard_method p {
    font-size: 20px;
  }

  .mfa_method p {
    font-size: 20px;
  }


}

@media (min-width: 280px) {
  .sd_logo {
    width: 200px
  }
}

@media (min-width: 600px) {
  .material_icon {
    width: 35px;
    height: 50px;
    margin-right: 25px;
  }

  .money_icon {
    width: 35px;
    height: 30px;
    margin-right: 25px;
  }


}

@media (min-width: 800px) {
}

@media (min-width: 1000px) {
  .ft_logo {
    width: 400px
  }
}

@media (min-width: 2000px) {
  .methods_intro h6 {
    font-family: 'Akshar', sans-serif;
    font-size: 32px;
  }

  .standard_method p {
  }


  .mfa_method p {
  }


}
</style>