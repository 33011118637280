<template>
  <input class="toggle" :class="{
                            toggle_unchecked:!new_state && !state_disabled,
                            toggle_disabled: state_disabled,
                        }"
         :disabled="state_disabled" v-model="the_state" type="checkbox" @change="onChange"/>
</template>

<script>
export default {
  name: "basic_toggle",
  props: [
    "uuid", "state_disabled", "new_state",
  ],
  async updated() {
    try {
      await this.$nextTick(function () {
        this.onChange()
      })
    } catch
        (error) {
      console.error(error)
    }
  },
  data() {
    return {
      the_state: this.current_state,
      change_stopper: false,
      // start_state: this.toggle_start_state
    }
  },
  methods: {
    onChange(value) {
      if(value === undefined){
        // console.log("Toggle onChange, value was undefined.")
        return null
      }
      if (this.the_state === true) {
        // console.log(`Toggle state is TRUE and value coming in was ${JSON.stringify(value, ["message", "arguments", "type", "name"])}`)
        console.log(`Toggle state is TRUE and value coming in was type:${value['type']}`)
        this.$emit('toggle-state_true', true)
        // this.toggle_state_to_true()
      } else {
        // console.log("Toggle state is FALSE")
        // console.log(`Toggle state is FALSE and value coming in was ${JSON.stringify(value, ["message", "arguments", "type", "name"])}`)
           console.log(`Toggle state is FALSE and value coming in was type:${value['type']}`)
        this.$emit('toggle-state_false', false)
        // this.toggle_state_to_false()
      }
    },
    toggle_toggle() {
      this.the_state = !this.the_state
    },
    toggle_state_to_false() {
      this.the_state = false
    },
    toggle_state_to_true() {
      this.the_state = true
    },

  },
  watch: {
    // current_state(new_v) {
    //   // console.log("in the watcher.........")
    //   // console.log(`State is ${new_v}`)
    //   this.the_state = new_v
    //   if(new_v){
    //     this.$emit('toggle-state_true', true)
    //   }else{
    //     this.$emit('toggle-state_false', false)
    //   }
    // },
  },
  computed: {
    current_state() {
      return this.new_state
    },
    toggle_state(){
      console.log(`new_state: ${this.new_state}`)
      console.log(`the_state: ${this.the_state}`)
      if(this.new_state !== undefined){
        console.log("toggle state new_state not undefined")
        return this.new_state
      }else if(this.the_state !== undefined){
        console.log("toggle state the_state not undefined")
        return this.the_state
      }else{
        console.log("toggle state was undefined")
        return false
      }
    },
    // toggle_state() {

    //   return this.toggle_state_
    // },
  }

}
</script>

<style scoped>

@media (min-width: 100px) {
  .toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
    padding: 0;
  appearance: none;
  width: 61px;
  height: 30px;
  display: inline-block;
  position: relative;
  border-radius: 2em;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  /*background-color: gray;*/
  transition: background-color ease 0.3s;
  font-family: Raleway, sans-serif;
  font-size: 2.4em;
  /*color: lightgray;*/
  margin-top: .2em;
}

.toggle_disabled {
  background-color: gray;
  color: lightgray;
  line-height: .8em;
}

.toggle_unchecked {
  content: "on  off";
  background-color: var(--cyanic-blue);
  color: black;
  font-weight: normal;
  line-height: .8em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

/*ball size control here*/
.toggle:before {
  content: "on off";
  display: block;
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  background: var(--eli_gray);
  border-radius: 50%;
  font-family: Raleway, sans-serif;
  font-size: 10px;
  box-sizing: border-box;
  border: 1px solid black;
  font-weight: bold;
  text-indent: -18px;
  word-spacing: 35px;
  text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);*/
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
  rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
  rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
  rgba(0, 0, 0, 0.06) 0px 2px 1px,
  rgba(0, 0, 0, 0.09) 0px 4px 2px,
  rgba(0, 0, 0, 0.09) 0px 8px 4px,
  rgba(0, 0, 0, 0.09) 0px 16px 8px,
  rgba(0, 0, 0, 0.09) 0px 32px 16px;
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
  background-color: var(--new-primary-purple-deep);
  color: white;
  line-height: 30px;
}

/*Push ball to right, adjust text*/
.toggle:checked:before {
  text-indent: -20px;
  content: "on";
  left: 31px;
}

}



@media (min-width: 450px) {

  /*Size of the ball and horizontal placement of 'off' cyanic mode*/
  .toggle:before {
    width: 35px;
    height: 35px;
    text-indent: -15px;
    word-spacing: 38px;
    font-size: 12px;
  }

  .toggle_unchecked {
    line-height: 35px;
  }

  /*verticle word placement cyanic mode*/
  .toggle_active {
    line-height: 33px;
  }

  .toggle_disabled {
    background-color: gray;
    color: lightgray;
    line-height: 35px;
  }

  /*verticle word placement purple mode*/
  .toggle:checked {
    line-height: 35px;
  }

  /*  Size of the control*/
  .toggle {
    width: 70px;
    height: 36px;
  }

  /*Move ball to right purple mode and horizontal text adjustment*/
  .toggle:checked:before {
    left: 35px;
    text-indent: -21px;
  }

}

</style>