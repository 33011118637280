<template>

  <section class="flex_centered_start_column market_box ">
    <h3 class="market_name text_align_center">{{ neighborhoods[0].market }}</h3>
<!--    <h3 class="market_name text_align_center">{{ neighborhoods }}</h3>-->
    <div class="market_core">

      <div v-for="(neighborhood, index) of neighborhoods" :key="`neighborhood-${index}`">
        <div class="flex_start_row opt_in_row ">
          <div class="flex_centered_column" @click="toggle_neighborhood_selection(neighborhood)">
            <div v-if="!neighborhood.setting_status">
              <img class="check_box_icon_false cursor_ptr"
                   src="@/assets/check-mark_basic_false.svg"
                   alt="check box image">
            </div>
            <div v-if="neighborhood.setting_status">
              <img class="check_box_icon cursor_ptr"
                   src="@/assets/check-mark_basic_true.svg"
                   alt="check box image">
            </div>
          </div>
          <div class="spacer"></div>
          <div class=" ">
            <p v-if="neighborhood.setting_status" class="opt_in_text_yes">{{ neighborhood.neighborhood }}</p>
            <p v-if="!neighborhood.setting_status" class="opt_in_text_no">{{ neighborhood.neighborhood }}</p>
          </div>

        </div>
      </div>

    </div>

  </section>


</template>
<script>
import {colors} from "@/library";

/* eslint-disable */
export default {
  name: "market_neighborhood_set",
  props: ['neighborhoods'],
  emits: ['toggle_neighborhood_selection'],
  data() {
    return {
      db: "",
      colors: colors,
      market_: 'Myrtle Beach Community'
    }
  },
  methods: {
    do_nothing() {
      //pass
    },
    toggle_neighborhood_selection(neighborhood){
      // this.do_nothing()
      this.$emit('toggle_neighborhood_selection', neighborhood)
    },
  },
  computed: {
    market() {
      return this.market_
    },
  },
}
</script>


<style scoped>
@import '../../common.css';

@media (min-width: 100px) {

  p {
    font-weight: 800;
  }


  .market_box {
    background-color: var(--cyanic-blue-light);
    max-width: 550px;
    margin-top: 40px;
  }

  .market_core {
    margin-top: 20px
  }

  .check_box_icon {
    height: 40px;
  }

  .check_box_icon_false{
    height: 30px;
    width: 40px;
  }

  .opt_in_row {
    margin-bottom: 20px;

  }

  .spacer {
    width: 30px;
  }

  .opt_in_text_yes {
  //font-weight: 800;
  }

  .market_name {
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 400px) {
}

@media (min-width: 500px) {
}

@media (min-width: 800px) {

  h1 {

  }

  h2 {

  }

  h3 {

  }

}

@media (min-width: 1200px) {
  h1 {

  }

  h2 {

  }

  h3 {

  }
}

@media (min-width: 2000px) {
}

h1 {

}

h2 {

}

h3 {

}
</style>